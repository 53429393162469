import React, { useEffect, useState } from 'react'
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";

import Content from './SubwayContent';

let lineStyle = "";

function SubwayItem(props) {
  const { subway, lat, lon, idx } = props;
  const { setStatnFNm, setStatnTNm, setSubwayNo, setOutStnNum, setSubwayIndex } = props;

  const [lineStyle, setLineStyle] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [isIndexOpen, setIsIndexOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    setIsIndexOpen(!isIndexOpen);
  }

  useEffect(() => {
    // 호선별 색상 처리
    var line = subway.lineNm;
    switch (line) {
      case '1호선':
      case '경인선':
      case '경부선':
      case '장항선':
        setLineStyle("line1_color");
        break;
      case '2호선':
        setLineStyle("line2_color");
        break;
      case '3호선':
        setLineStyle("line3_color");
        break;
      case '4호선':
        setLineStyle("line4_color");
        break;
      case '5호선':
        setLineStyle("line5_color");
        break;
      case '6호선':
        setLineStyle("line6_color");
        break;
      case '7호선':
      case '7호선(인천)':
        setLineStyle("line7_color");
        break;
      case '8호선':
        setLineStyle("line8_color");
        break;
      case '9호선':
      case '9호선(연장)':
        setLineStyle("line9_color");
        break;
      case '분당선':
      case '수인선':
        setLineStyle("line_b_color");
        break;
      case '신분당선':
      case '신분당선(연장)':
      case '신분당선(연장2)':
        setLineStyle("line_nb_color");
        break;
      case '경의중앙선':
      case '중앙선':
        setLineStyle("line_gj_color");
        break;
      case '공항철도1호선':
        setLineStyle("line_air_color");
        break;
      case '인천1호선':
        setLineStyle("line_im1_color");
        break;
      case '경춘선':
        setLineStyle("line_gc_color");
        break;
      case '신림선':
        setLineStyle("line_sillim_color");
        break;
      default:
        setLineStyle("");
    }

  }, [subway]);

  useEffect(() => {
    if (idx === 0) {
      setIsOpen(!isOpen);
      setIsIndexOpen(!isIndexOpen);
    }
  }, [idx]);

  // 새로고침시 이전에 선택한 리스트 클릭 처리
  // useEffect (() => {
  //   const stnKrNm = sessionStorage.getItem(`__select_subway_name`);
  //   let lineId = sessionStorage.getItem(`__select_subway_line_id`);

  //   if (stnKrNm) {
  //     // 호선이 null로 들어오는 경우
  //     if (lineId === "null") {
  //       lineId = null;
  //     }

  //     let isClicked1 = subway.stnKrNm === stnKrNm ? true : false;
  //     let isClicked2 = subway.lineId === lineId ? true : false;

  //     // 역 명과 라인이 같으면 리스트 클릭 처리하여 리스트에서 클릭된 것처럼 표시
  //     if (isClicked1 && isClicked2) {
  //       setIsIndexOpen(isClicked1);
  //       setIsOpen(isClicked1);
  //     }
  //   }
  // }, []);

  return (
    <motion.div className="pb-1 mb-0 small lh-sm border-bottom w-100">
      <motion.div className={'d-flex justify-content-between ' + (lineStyle ? lineStyle : "")} layout onClick={toggleOpen} initial={{ borderRadius: 10 }} style={{ height: "50px", padding: "6px" }}>
        <motion.span>
          {subway.stnKrNm}<br />
          {subway.lineNm}
        </motion.span>
        <motion.span>{subway.distance}m</motion.span>
      </motion.div>
      <AnimatePresence>
        {isOpen && isIndexOpen && (
          <motion.div
            key="content"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Content
              subway={subway}
              lat={lat}
              lon={lon}
              isOpen={isOpen}
              isIndexOpen={isIndexOpen}
              lineStyle={lineStyle}
              setStatnFNm={setStatnFNm}
              setStatnTNm={setStatnTNm}
              setSubwayNo={setSubwayNo}
              setOutStnNum={setOutStnNum}
              setSubwayIndex={setSubwayIndex}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}



export default SubwayItem;