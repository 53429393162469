import React from 'react';
import MainLayout from '../layout/MainLayout';
import AdminContainer from '../container/AdminContainer';

function AdminPage() {
  return (
    <div>
        <MainLayout>
            <AdminContainer />
        </MainLayout>      
    </div>
  )
}

export default AdminPage;