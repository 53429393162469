import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import * as client from '../../lib/api';
import ReLoading from '../common/ReLoading';

const style = {
    margin: "0"
}

function SubwayContent(props) {

    const { subway, lat, lon, isOpen, isIndexOpen, lineStyle } = props;
    const { setStatnFNm, setStatnTNm, setSubwayNo, setOutStnNum, setSubwayIndex } = props;

    const [subwayArrival, setSubwayArrival] = useState([]); // 도착 정보

    const [arrivalInfo1, setArrivalInfo1] = useState("");   // 왼쪽 도착 정보
    const [timer1, setTimer1] = useState("0");              // 왼쪽 도착 시간 정보
    const [congestion1, setCongestion1] = useState("");     // 왼쪽 혼잡도(미사용)

    const [arrivalInfo2, setArrivalInfo2] = useState("");   // 오른쪽 도착 정보
    const [timer2, setTimer2] = useState("0");              // 오른쪽 도착 시간 정보
    const [congestion2, setCongestion2] = useState("");     // 오른쪽 혼잡도(미사용)

    // const [timer1_1, setTimer11] = useState("0");
    // const [timer2_2, setTimer22] = useState("0");
    // const [isSearch1, setIsSearch1] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // const navigate = useNavigate();

    // 지도로 확인하기 버튼 클릭 
    // 상세 정보값만 전달하여 화면 전환
    const onClickMap = () => {
        var fName, tName;
        // 역 도착 정보가 있는 경우
        if (subwayArrival.length > 0) {
            fName = subwayArrival[0].statnFNm;  // 이전 역 이름
            tName = subwayArrival[0].statnTNm;  // 다음 역 이름
            console.log("Map statnFNm:" + subwayArrival[0].statnFNm);
            console.log("Map statnTNm:" + subwayArrival[0].statnTNm);
            setStatnFNm(fName);
            setStatnTNm(tName);
        } else {
            // 역 도착정보가 없는 경우
            setStatnFNm(null);
            setStatnTNm(null);
        }
        // 상세화면에서 선택한 라인과 역명으로 위치변경시 유지하기 위함
        sessionStorage.setItem(`__select_subway_name`, subway.stnKrNm);     // 지하철 역 명
        sessionStorage.setItem(`__select_subway_line_id`, subway.lineId);   // 지하철 호선
        
        setSubwayNo(subway.subwayNo);   // 열차번호
        setOutStnNum(subway.outStnNum); // 외구간 역 수?
        setSubwayIndex(subway.stnKrNm);       // 지하철 역 명
        
        // 스크롤 위치 저장
        sessionStorage.setItem(
            `__next_scroll_subway`,
            JSON.stringify({
                x: window.pageXOffset,
                y: window.pageYOffset,
            })
        );
        // navigate("/map?subwayNo=" + subway.subwayNo + "&outStnNum=" + subway.outStnNum + "&statnFNm=" + fName + "&statnTNm=" + tName + "&lat=" + lat + "&lon=" + lon);
    };

    const getSubwayArrival = async () => {

        try {
            setIsLoading(true);
            // 지하열역 명, 호선으로 도착지 정보 조회
            const response = await client.fetchSubwayArrival(subway.stnKrNm, subway.lineId);
            console.log(response);

            setSubwayArrival(response.data);
            if (response.data.length > 0) {
                console.log(response.data[0].barvlDt);  // 왼쪽 도착 예정 시간
                console.log(response.data[1].barvlDt);  // 오른쪽 도착 예정 시간


                // 열차도착예정시간(초) => 1호선의 경우 전부 0으로 표시됨
                if (response.data[0].barvlDt === "0") {
                    setArrivalInfo1(response.data[0].arvlMsg2);
                } else {
                    setTimer1(response.data[0].barvlDt);
                }
                if (response.data[1].barvlDt === "0") {
                    setArrivalInfo2(response.data[1].arvlMsg2);
                } else {
                    setTimer2(response.data[1].barvlDt);
                }

                // 2호선,3호선인 경우 열차 혼잡도 조회
                // Free버전은 월 10번만 기능 동작
                if (response.data[0].subwayId === '1002' || response.data[0].subwayId === '1003') {
                    const congestionRes1 = await client.fetchCongestionTrain(response.data[0].subwayId, response.data[0].btrainNo);
                    var congestion1Msg = congestionRes1.data.congestionTrain;
                    if (congestion1Msg) {
                        setCongestion1(congestion1Msg);
                    }

                    const congestionRes2 = await client.fetchCongestionTrain(response.data[1].subwayId, response.data[1].btrainNo);
                    var congestion2Msg = congestionRes2.data.congestionTrain;
                    if (congestion2Msg) {
                        setCongestion2(congestion2Msg);
                    }
                }

            }

            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            throw e;
        }
    }

    // 15초마다 도착정보 갱신
    useEffect(() => {
        getSubwayArrival();

        // subway 화면에서만 동작하도록 변경
        const interval = setInterval(() => {
            const currentUrl = sessionStorage.getItem(`__current_url`);
            if (currentUrl === "subway") {
                getSubwayArrival();
            }
        }, 15000);

        //}else{
        return () => clearInterval(interval);
    }, [subway, isOpen]);

    useEffect(() => {

        let minutes = 0;
        let seconds = 0;
        console.log("handle:" + timer1);

        if (timer1 !== 0) {
            minutes = timer1 / 60;
            seconds = timer1 % 60;
        } else {
            minutes = 0;
            seconds = 0;
        }
        //interval 1초전 우선 실행
        if (parseInt(seconds) > 0) {
            seconds = parseInt(seconds) - 1;
            setArrivalInfo1(seconds + "초");
        }
        if (parseInt(seconds) === 0) {
            if (parseInt(minutes) === 0) {
                if (timer1 !== "0") {
                    setArrivalInfo1("도착 또는 출발");
                }
            } else {
                minutes = (parseInt(minutes) - 1);
                seconds = 59;
                setArrivalInfo1(minutes + "분 " + seconds + "초");
            }
        } else {
            if (parseInt(minutes) > 0) {
                minutes = (parseInt(minutes));
                setArrivalInfo1(minutes + "분 " + seconds + "초");
            }
        }

        console.log("handle11:" + timer1);
        const countdown = setInterval(() => {
            if (parseInt(seconds) > 0) {
                seconds = parseInt(seconds) - 1;
                setArrivalInfo1(seconds + "초");
            }
            if (parseInt(seconds) === 0) {
                if (parseInt(minutes) === 0) {
                    if (timer1 !== "0") {
                        setArrivalInfo1("도착 또는 출발");
                    }
                    clearInterval(countdown);
                } else {
                    minutes = (parseInt(minutes) - 1);
                    seconds = 59;
                    setArrivalInfo1(minutes + "분 " + seconds + "초");
                }
            } else {
                if (parseInt(minutes) > 0) {
                    minutes = (parseInt(minutes));
                    setArrivalInfo1(minutes + "분 " + seconds + "초");
                }
            }
        }, 1000);
        return () => clearInterval(countdown);

    }, [timer1]);

    useEffect(() => {
        let minutes = 0;
        let seconds = 0;
        if (timer2 !== 0) {
            console.log(timer2 !== 0);
            minutes = timer2 / 60;
            seconds = timer2 % 60;
        } else {
            minutes = 0;
            seconds = 0;
        }
        //interval 1초전 우선 실행
        if (parseInt(seconds) > 0) {
            seconds = parseInt(seconds) - 1;
            setArrivalInfo2(seconds + "초");
        }
        if (parseInt(seconds) === 0) {
            if (parseInt(minutes) === 0) {
                if (timer2 !== "0") {
                    setArrivalInfo2("도착 또는 출발");
                }
            } else {
                minutes = (parseInt(minutes) - 1);
                seconds = 59;
                setArrivalInfo2(minutes + "분 " + seconds + "초");
            }
        } else {
            if (parseInt(minutes) > 0) {
                minutes = (parseInt(minutes));
                setArrivalInfo2(minutes + "분 " + seconds + "초");
            }
        }

        const countdown = setInterval(() => {
            if (parseInt(seconds) > 0) {
                seconds = parseInt(seconds) - 1;
                setArrivalInfo2(seconds + "초");
            }
            if (parseInt(seconds) === 0) {
                if (parseInt(minutes) === 0) {
                    if (timer2 !== "0") {
                        setArrivalInfo2("도착 또는 출발");
                    }
                    clearInterval(countdown);
                } else {
                    minutes = (parseInt(minutes) - 1);
                    seconds = 59;
                    setArrivalInfo2(minutes + "분 " + seconds + "초");
                }
            } else {
                if (parseInt(minutes) > 0) {
                    minutes = (parseInt(minutes));
                    setArrivalInfo2(minutes + "분 " + seconds + "초");
                }
            }
        }, 1000);
        return () => clearInterval(countdown);

    }, [timer2]);

    useEffect(() => {

        if (isLoading) {
            const loding = setInterval(() => {
                setIsLoading(false);
            }, 1000);

            return () => clearInterval(loding);
        }

    }, [isLoading]);

    const renderCongestion = (congestion) => {
        var congestionCd = "";
        if (congestion !== null && congestion <= 34)
            congestionCd = "1";
        else if (congestion > 34 && congestion <= 100)
            congestionCd = "2";
        else if (congestion > 100 && congestion <= 150)
            congestionCd = "3";
        else if (congestion > 150 && congestion <= 230)
            congestionCd = "4";

        switch (congestionCd) {
            case "1":
                return <button class='btn btn-primary btn-sm'>여유</button>;
            case "2":
                return <button class='btn btn-success btn-sm'>보통</button>;
            case "3":
                return <button class='btn btn-warning btn-sm'>주의</button>;
            case "4":
                return <button class='btn btn-danger btn-sm'>혼잡</button>;
            default:
                return null;

        }
    };

    const getFetchSubway = async () => {
        const date = new Date();
        // 년, 월, 일, 시간, 분, 초를 각각 추출합니다.
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        // yyyy-mm-dd HH:MM:SS 형식으로 날짜를 조합합니다.
        const formattedDate = `${year}${month}${day}${hours}${minutes}${seconds}`;
        sessionStorage.setItem(`date`, formattedDate);

        sessionStorage.setItem(`sub_name`, subway.stnKrNm);
        sessionStorage.setItem(`sub_line`, subway.lineId);
        sessionStorage.setItem(`div_cd`, 'sub');

        // 상점 내용 삭제
        sessionStorage.removeItem(`str_name`);
        sessionStorage.removeItem(`str_date`);
        sessionStorage.removeItem(`str_no`);
        sessionStorage.removeItem(`str_addr`);
        sessionStorage.removeItem(`str_menu`);
        sessionStorage.removeItem(`str_tel`);

        const url = document.location.href;
        const splitUrl = url.split('/');
        const location = splitUrl[splitUrl.length - 1];

        const keys = Object.keys(sessionStorage);
        let json = {}

        // 각 키를 이용하여 값(value)에 접근하고 출력하기
        keys.forEach(key => {
            let value = sessionStorage.getItem(key);

            if (!key.includes('__')) {
                json[key] = value;
            }
        });

        client.fetchSave(json);
    }

    useEffect(() => {
        getFetchSubway();
    }, [isOpen, isIndexOpen]);

    return (
        <motion.div className={lineStyle}
            layout
            initial={{ opacity: 0, borderRadius: 10 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {
                subwayArrival && (
                    subwayArrival.map((arrival, index) => {
                        return (
                            (index === 1 &&
                                <div key={arrival} className="station_div_load">
                                    <span key={arrival.statnFNm} className='station_left'>[◀{arrival.statnFNm}]</span>
                                    <span className='station_loading' style={style}>{isLoading === true ? <ReLoading /> : <img />}</span>
                                    <span key={arrival.statnTNm} className='station_right'>[{arrival.statnTNm}▶]</span>
                                </div>
                            )
                        )
                    })
                )
            }
            <div className="station">
                {
                    subwayArrival && (

                        subwayArrival.map((arrival, index) => {
                            return (

                                index === 0 ?
                                    <span key={arrival.statnId} className='station_left'>{arrivalInfo1}</span>
                                    : <span className='station_right'>{arrivalInfo2}</span>

                            )
                        })
                    )
                }
            </div>
            {
                (congestion1 || congestion2) && (
                    <div className="station">
                        <>
                            <span className='station_left'>{renderCongestion(congestion1)}</span>
                            <span className='station_right'>{renderCongestion(congestion2)}</span>
                        </>
                    </div>
                )
            }
            {
                <>
                    <button type="button" onClick={onClickMap}
                        className="btn btn-dark mx-1 mt-1" style={{ width: "98%" }} >지도로 확인하기</button>
                </>
            }

        </motion.div>
    );
}

export default SubwayContent;