import React, { useEffect, useState } from 'react';
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import { ChevronDown } from 'react-bootstrap-icons';
import Intelligence from '../../assets/artificial-intelligence.png';

import Content from './RecommendContent';

function RecommendItem(props) {
    const { recommendList, lat, lon, lineStyle, parentId } = props;
    const { getDetail } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [isIndexOpen, setIsIndexOpen] = useState(false);
    // const [parentOpen, setParentOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
        setIsIndexOpen(!isIndexOpen);
    }

    // useEffect(() => {
    //     toggleOpen();
    // }, [parentOpen]);

    return (
        <motion.div className="pb-1 mb-0 small lh-sm border-bottom w-100">
            <motion.div className={`d-flex justify-content-between ${lineStyle}`} layout onClick={toggleOpen} initial={{ borderRadius: 10 }} style={{ height: "50px", padding: "6px", alignItems: "center" }}>
                <motion.span>
                    {(parentId === '딥러닝 리뷰 감정분석') && <img src={Intelligence} style={{ width: "30px", display: "inline" }} alt="Intelligence icon" />}
                    {parentId ? parentId : recommendList[0].crawlReviewNm1} ({recommendList.length})
                </motion.span>
                <motion.span style={{ textAlign: "right" }}>
                    <ChevronDown />
                </motion.span>
            </motion.div>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        key="content"
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        {/* Content 컴포넌트 */}
                        <Content recommendList={recommendList} lat={lat} lon={lon} isOpen={isOpen} getDetail={getDetail} parentId={parentId} setIsOpen={setIsOpen} /*setParentOpen={setParentOpen}*//>
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
    )
}

export default RecommendItem;