import React, { useEffect, useState } from 'react';
import Location from '../Location';
import Loading from '../common/Loading';
import dayjs from 'dayjs';
import BackButton from '../common/BackButton';

const style = {
    background: "#FFFFFF",
    padding: "10px",
    paddingTop: "60px"
}
const btnStyle = {
    width: "47%"
}

const timeStyle = {
    color: "#0d6efd",
    fontWeight: "bold"
}

function SubwayDetail(props) {
    const { subway, timeTable, statnFNm, statnTNm, isLoading, setSubwayIndex } = props;
    const [locType, setLocType] = useState("");
    const [mapType, setMapType] = useState("subway");
    const [estimatedTime, setEstimatedTime] = useState("");
    const [totalEstimatedTime, setTotalEstimatedTime] = useState(0);

    const setTotalTime = (workTime, totalTime) => {
        console.log("totalTime :" + totalTime);
        setTotalEstimatedTime(totalTime);
        setEstimatedTime(workTime);
    }

    // useEffect(()=>{
    //     window.scrollTo(0, 0);
    //     setMapType("subway");
    // },[subway]);

    const clickPath = (e) => {
        console.log(e.target.value);
        setLocType(e.target.value);
        console.log(locType);
    }

    // 만보 걷기 시간을 얻기 위함
    // useEffect(() => {
    //     let temp = sessionStorage.getItem(`temp`);
    //     temp = JSON.parse(temp);
    //     if (estimatedTime !== '' && temp !== '' && temp !== undefined) {
            
    //     }

    // }, [estimatedTime]);

    // 열차 시간표
    const renderTimeTable = () => {
        console.log("renderTime:::::::::::::::::" + totalEstimatedTime);
        const date = new Date();
        if (totalEstimatedTime === 0) {
            date.setHours(date.getHours() + 1);
        } else {
            if (parseInt(totalEstimatedTime / 60) === 0) {
                date.setSeconds(date.getSeconds() + totalEstimatedTime % 60);
            } else {
                parseInt(totalEstimatedTime / 60)
                date.setMinutes(date.getMinutes() + totalEstimatedTime / 60);
                date.setSeconds(date.getSeconds() + totalEstimatedTime % 60);
            }
        }
        var arrivalTime = dayjs(date).format('HH:mm:ss');
        console.log(dayjs(date).format('HH:mm:ss'));

        return (
            timeTable.map((time, index) => {
                return (
                    <>
                        {
                            (index + 1) === 1
                                ?
                                <tr>
                                    <td className="text-center fw-bold">[◀{statnTNm}]</td>
                                    <td className="text-center fw-bold">[{statnFNm}▶]</td>
                                </tr>
                                : ""
                        }
                        <tr>
                            <td style={arrivalTime < time.outArriveTime ? { color: "#0d6efd", fontWeight: "bold" } : null}>{dayjs('20230101 ' + time.outArriveTime).format('A hh:mm:ss')}</td>
                            <td style={arrivalTime < time.inArriveTime ? { color: "#0d6efd", fontWeight: "bold" } : null}>{dayjs('20230101 ' + time.inArriveTime).format('A hh:mm:ss')}</td>
                        </tr>
                    </>
                )
            })
        )
    }

    return (
        <>
            {isLoading && <Loading />}
            {!isLoading && subway && (
                <>
                    <main className="container">
                        <div className="col text-center" style={style} >
                            <button className="btn btn-primary mx-1"
                                style={btnStyle}
                                value="pedestrain"
                                onClick={clickPath}
                            >
                                만보걷기 ({estimatedTime})
                            </button>
                            <button className="btn btn-danger mx-1"
                                style={btnStyle}
                                value="drive"
                                onClick={clickPath}
                            >
                                카푸어
                            </button>
                            {statnFNm !== undefined && statnFNm !== 'undefined' && statnFNm !== null && statnFNm !== '' && (
                                <table>
                                    {renderTimeTable()}
                                </table>
                            )}
                        </div>
                        <Location subway={subway} locType={locType} mapType={mapType} setTotalTime={setTotalTime} />
                        <BackButton setSubwayIndex={setSubwayIndex} />
                    </main>
                </>
            )}
        </>
    )
}

export default SubwayDetail;