import React, { useState, useEffect, useRef } from "react";
// import Typewriter from 'react-ts-typewriter';   // 타자기 효과 애니메이션 효과 라이브러리
import '../offcanvas.css';
import * as client from '../lib/api';
import LoadingImg from '../assets/loading.gif';

const ChatContainer = (props) => {
    const { url } = props;
    const [messages, setMessages] = useState([]);
    const [currentTypingId, setCurrentTypingId] = useState(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const messagesEndRef = useRef(null);

    // const [number, setNumber] = useState('');

    // const handleChange = (event) => {
    //     setNumber(event.target.value);
    // };

    // const handleSendMessage = (message, llmMessage) => {
    //     setMessages((prevMessages) => [
    //         ...prevMessages,
    //         { text: message, isUser: true },
    //         {
    //             text: `${llmMessage}`,
    //             isUser: false,
    //             isTyping: true,
    //             id: Date.now(),
    //         },
    //     ]);
    // };

    const handleSendMessage = (message, llmMessage) => {
        setMessages((prevMessages) => [
            ...prevMessages,
            { text: message, isUser: true },
            {
                text: `${llmMessage}`,
                isUser: false,
                isTyping: true,
                id: Date.now(),
            },
        ]);
    };

    const handleEndTyping = (id) => {
        setMessages((prevMessages) =>
            prevMessages.map((msg) =>
                msg.id === id ? { ...msg, isTyping: false } : msg
            )
        );
        setCurrentTypingId(null);
    };

    // 스크롤을 하단으로 이동하는 함수
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    // messages 상태가 업데이트될 때마다 스크롤을 하단으로 이동
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    // const changeList = () => {
    //     sessionStorage.setItem(`llm_size`, number);
    //     alert("변경 성공");
    // }

    // const changeReset = () => {
    //     sessionStorage.removeItem(`llm_size`);
    //     alert("전체 데이터 변경");
    // }

    return (
        <div className={(url === 'chat' ? 'modal-close' : 'minus-index')}>
            {/* <div style={{display: "flex", justifyContent: "space-around"}}>
                <input type="text" style={{
                    backgroundColor: "lightgray"
                }}
                value={number}
                onChange={handleChange}
                ></input>
                <button onClick={changeList}>변경</button>
                <button onClick={changeReset}>전체</button>
            </div> */}
            <div className={"chat-container"} >
                <div className="messages-list">
                    <div className="user-message" style={{ textAlign: "right", marginLeft: "30%" }}>
                        <p>
                            <span className="message-text" style={{ whiteSpace: "pre-wrap" }} >
                                ※ 거리가 가까운 30건의 상점에 대하여 제공됨<br />
                                가장 먼 상점 : {sessionStorage.getItem(`max_distance`)}m
                            </span>
                        </p>
                    </div>
                    {messages.map((message, index) => (
                        <Message
                            key={index}
                            {...message}
                            onEndTyping={handleEndTyping}
                            currentTypingId={currentTypingId}
                            setIsButtonDisabled={setIsButtonDisabled}
                        />
                    ))}
                    {/* 스크롤 위치를 조정할 요소 */}
                    <div ref={messagesEndRef} />
                </div>
            </div>
            <MessageForm
                onSendMessage={handleSendMessage}
                setIsButtonDisabled={setIsButtonDisabled}
                isButtonDisabled={isButtonDisabled}
            />
        </div>
    );
};

const Message = ({ text, isUser, isTyping, id, onEndTyping, setIsButtonDisabled, currentTypingId }) => {
    useEffect(() => {
        if (!isUser && !isTyping && id === currentTypingId) {
            onEndTyping(id);
        }
    }, [isUser, isTyping, id, onEndTyping, currentTypingId]);

    return (
        <div className={isUser ? 'user-message' : 'ai-message'}
            style={{ textAlign: isUser ? 'right' : 'left', marginLeft: isUser ? '30%' : '', marginRight: isUser ? '' : '30%', whiteSpace: isUser ? '' : 'pre-wrap' }}>
            {isTyping ? (
                <div>
                    {/* Typing animation component */}
                    <Typewriter
                        text={text}
                        onFinished={() => {
                            console.log('Typing animation ended');
                            onEndTyping(id);
                            setIsButtonDisabled(false);
                        }}
                    />
                    {/* Typing indicator */}
                    <p>
                        <span className="typing-indicator">Typing...</span>
                    </p>
                </div>
            ) : (
                // Display message text
                <p>
                    <span className="message-text" style={{ whiteSpace: 'pre-wrap' }}>{text}</span>
                </p>
            )}
        </div>
    );
};

const MessageForm = ({ onSendMessage, setIsButtonDisabled, isButtonDisabled }) => {
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        setIsLoading(true);
        setIsButtonDisabled(true);
        setMessage("");
        event.preventDefault();
        if (message.trim() === "") {
            return; // 빈 메시지 전송 방지
        }
        await listStore(message);
        onSendMessage(message, responseData);
    };

    let responseData = "";
    const listStore = async (message) => {

        try {
            const reviewTxt = sessionStorage.getItem(`review_txt`);

            const values = [reviewTxt, message];
            const keys = ['reviewTxt', 'message'];


            let json = {};
            keys.forEach((key, index) => {
                json[key] = values[index];
            });

            const llm_size = sessionStorage.getItem(`llm_size`);

            if (llm_size !== null) {
                json['llmSize'] = llm_size;
            }

            const response = await client.fetchChat(json);

            responseData = response.data;

            setIsLoading(false);
        } catch (e) {
            responseData = "연결 실패"
            setIsLoading(false);
            throw e;
        }
    }

    return (
        <form onSubmit={handleSubmit} className="message-form" style={{
            width: "100%",
            position: "fixed",
            bottom: "60px"}}>
            {isLoading &&
                <div style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    flex: "1",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    marginRight: "10px",
                    lineHeight: "40px"
                }}>
                    <img src={LoadingImg} alt="로딩중" width="15%" />
                    <div style={{

                    }}>잠시만 기다려 주세요.</div>
                </div>
            }
            {!isLoading &&
                <input
                    type="text"
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                    className="message-input"
                    placeholder="Type your message..."
                />}
            <button type="submit" className="send-button" disabled={isButtonDisabled || message.trim() === ""}>
                Send
            </button>
        </form>
    );
};

const DEFAULT_MS = 30;
const Typewriter = ({ text, speed = DEFAULT_MS, loop = false, random = DEFAULT_MS, delay = DEFAULT_MS, cursor = true, onFinished = () => { }, onStart = () => { } }) => {
    const [currentStringIndex, setCurrentStringIndex] = useState(0);
    const [currentTextIndex, setCurrentTextIndex] = useState(0);


    if (!Array.isArray(text))
        text = [text];
    useEffect(() => {
        setTimeout(() => {
            if (currentTextIndex === 0)
                onStart();
            if (currentTextIndex < text[currentStringIndex].length) {
                setCurrentTextIndex(currentTextIndex + 1);
            }
            else {
                if (currentStringIndex < text.length - 1) {
                    setTimeout(() => {
                        setCurrentTextIndex(0);
                        setCurrentStringIndex(currentStringIndex + 1);
                    }, delay);
                }
                else {
                    if (loop) {
                        setTimeout(() => {
                            setCurrentTextIndex(0);
                            setCurrentStringIndex(0);
                        }, delay);
                    }
                    else {
                        onFinished();
                    }
                }
            }
        }, speed + (Math.random() * random));
    });

    useEffect(() => {
        const myDiv = document.querySelector('.messages-list');
        if (myDiv) {
            myDiv.scrollTop = myDiv.scrollHeight;
        }
    }, [text]);

    return (React.createElement("span", null,
        text[currentStringIndex].substring(0, currentTextIndex),
        React.createElement("span", { style: { animation: "blinkingText 1s infinite" } }, cursor && '▎')));
}

export default ChatContainer;

