import React, { useState, useEffect } from 'react';
import StoreList from '../component/store/StoreList';
import Dropdown from '../component/store/Dropdown';
import StoreFilter from '../component/store/StoreFilter';
import TopButton from '../component/common/TopButton';
import * as client from '../lib/api';
import { CaretDownSquare, CaretUpSquare, X } from 'react-bootstrap-icons';

const StoreListContainer = (props) => {
    const { setIndex } = props;
    const [storeList, setStoreList] = useState([]);
    const [uptaeList, setUptaeList] = useState([]);
    const [search, setSearch] = useState("");
    const [uptaeFilter, setUptaeFilter] = useState("");
    const [filterName, setFilterName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    // const { key } = useLocation();
    const [viewCnt, setViewCnt] = useState(50);
    const [lat, setLat] = useState("");
    const [lon, setLon] = useState("");

    const listStore = async (lat, lon) => {

        try {
            window.scrollTo(0, 0);
            // 리스트 목록 갯수
            const listCntInfo = sessionStorage.getItem(`__next_scroll_cnt`);
            if (listCntInfo) {
                const { listCnt } = JSON.parse(listCntInfo);
                setViewCnt(listCnt);
            }
            setIsLoading(true);

            // 임의로 위치를 변경했을 경우 해당 위치로 주소 변경
            if (sessionStorage.getItem(`__change_lat`) || sessionStorage.getItem(`__change_lon`)) {
                lat = sessionStorage.getItem(`__change_lat`);
                lon = sessionStorage.getItem(`__change_lon`);

                console.log("변경 위치 : " + lat);
                console.log("변경 위치 : " + lon);
            }

            // 리스트 목록 조회
            const response = await client.fetchStoreList(lat, lon);

            sessionStorage.setItem(`loc_addr`, response.data.fullAddress);  // 위치 주소
            sessionStorage.setItem(`max_distance`, response.data.maxDistance);
            setStoreList(response.data.storeList);
            props.setAddress(response.data.fullAddress.substr(6));
            setUptaeList(response.data.uptaeList);

            sessionStorage.setItem(`review_txt`, response.data.ragReview);

            setFilterName('업종선택');
            setSearch('');
            setUptaeFilter('');

            const searchInfo = sessionStorage.getItem(`__next_scroll_search`);
            if (searchInfo) {
                const { search } = JSON.parse(searchInfo);
                setSearch(search);
            }

            const uptaeInfo = sessionStorage.getItem(`__next_scroll_uptae`);
            if (uptaeInfo) {
                const { uptaeNm, uptaeCnt } = JSON.parse(uptaeInfo);
                setUptaeFilter(uptaeNm);
                setFilterName(uptaeNm + "(" + uptaeCnt + ")");
            }

            setIsLoading(false);

            const _tempScroll = sessionStorage.getItem(`__next_scroll_temp`);
            if (_tempScroll) {
                const { tempScroll } = JSON.parse(_tempScroll);

                // 상점에 대한 좌표값
                if (tempScroll) {
                    window.scrollTo(0, tempScroll);
                }
            }
        } catch (e) {
            setIsLoading(false);
            sessionStorage.clear();
            throw e;
        }
    }

    const [dropdownVisibility, setDropdownVisibility] = React.useState(false);

    const getUptaeData = (uptae, cnt) => {
        setUptaeFilter(uptae);
        sessionStorage.removeItem(`__next_from_page`);
        if (uptae === "") {
            setFilterName("전체(" + cnt + ")");
            sessionStorage.removeItem(`__next_scroll_uptae`);
        } else {
            setFilterName(uptae + "(" + cnt + ")");
            sessionStorage.setItem(
                `__next_scroll_uptae`,
                JSON.stringify({
                    uptaeNm: uptae,
                    uptaeCnt: cnt
                })
            );
        }

    }

    // 상점정보 sessionStorage에 저장
    const getSelectStore = (storeNo) => {
        console.log("LIST Container :" + storeNo);
        sessionStorage.setItem(
            `__select_store_No`,
            JSON.stringify({
                selectStoreNo: storeNo,
            })
        );
        sessionStorage.setItem(
            `__next_scroll_Id`,
            JSON.stringify({
                storeNo: storeNo,
            })
        );
        sessionStorage.setItem(
            `__next_scroll_${storeNo}`,
            JSON.stringify({
                x: window.pageXOffset,
                y: window.pageYOffset,
            })
        );
        sessionStorage.setItem(
            `__next_scroll_temp`,
            JSON.stringify({
                storeNo: `${storeNo}`,
                tempScroll: window.pageYOffset,
            })
        );
    }

    // 상점 상세정보 조회한 내용
    const getDetail = (response) => {
        props.setResponse(response);
    }

    const setListCnt = (cnt) => {
        setViewCnt(cnt);
        console.log("Container cnt:" + cnt);
        sessionStorage.setItem(
            `__next_scroll_cnt`,
            JSON.stringify({
                listCnt: cnt,
            })
        );
    }

    // 검색기능
    const setSearchWord = (e) => {
        var searchWord = e.target.value;
        setSearch(searchWord);
        sessionStorage.removeItem(`__next_from_page`);
        console.log("Container search:" + searchWord);
        if (searchWord === '') {
            sessionStorage.removeItem(`__next_scroll_search`);
        } else {
            sessionStorage.setItem(
                `__next_scroll_search`,
                JSON.stringify({
                    search: searchWord,
                })
            );
        }
    }

    const clearInput = () => {
        setSearch('');
        sessionStorage.removeItem(`__next_scroll_search`);
    }

    let style = {
        color: "#FAF9B3"
    }

    useEffect(() => {
        console.log('현재 위치 구하기');
        // 현재 위치 좌표 구해서, 가게 목록 조회
        navigator.geolocation.getCurrentPosition((position) => {
            var lat = position.coords.latitude;
            var lon = position.coords.longitude;

            if (sessionStorage.getItem(`__change_lat`) || sessionStorage.getItem(`__change_lon`)) {
                lat = sessionStorage.getItem(`__change_lat`);
                lon = sessionStorage.getItem(`__change_lon`);

                console.log("변경 위치 : " + lat);
                console.log("변경 위치 : " + lon);
            }

            console.log('lat', lat);
            console.log('lon', lon);
            listStore(lat, lon);
            setLat(lat);
            setLon(lon);

            // 상점 상세화면에 좌표값을 넘기기 위함
            props.setLat(lat);
            props.setLon(lon);
        })
    }, []);

    // 상점 상세정보가 변경되는 경우
    useEffect(() => {
        const no = sessionStorage.getItem(`__next_scroll_Id`);
        if (no) {
            const { storeNo } = JSON.parse(no);
            setIndex(storeNo);
        }
    }, [getDetail]);

    // 로딩 상태 감지
    useEffect(() => {
        if (isLoading) {
            // 로딩중인 경우 잠시만 기다려 주세요 화면으로 가림 처리 후 뒤 페이지 스크롤 막기
            const styleElement = document.createElement('style');
            styleElement.textContent = 'html { overflow: hidden; }';
            document.head.appendChild(styleElement);
        } else {
            // 로딩이 완료된 이후 막은 스크롤 제거
            // 적용된 스타일 (스크롤 방지)
            const styleElements = document.querySelectorAll('style');

            // NodeList를 배열로 변환
            const styleArray = Array.from(styleElements);

            // 배열의 마지막 요소 선택
            const lastStyleElement = styleArray.pop();

            // 마지막 요소가 존재하면 삭제
            if (lastStyleElement) {
                lastStyleElement.parentNode.removeChild(lastStyleElement);
            }
        }
    }, [isLoading]);

    return (
        <main className="container" id='container-list'>
            <div className="align-items-center p-2 my-2 text-white rounded" onClick={e => setDropdownVisibility(!dropdownVisibility)}>
                {
                    dropdownVisibility
                        ? <CaretUpSquare size={25} style={{ display: "inline" }} />
                        : <CaretDownSquare size={25} style={{ display: "inline" }} />
                }
                <label id="uptaeNm" className="FilterLabel" style={style}>{filterName}</label>
                <Dropdown visibility={dropdownVisibility}>
                    <StoreFilter uptaeList={uptaeList} getUptaeData={getUptaeData} />
                </Dropdown>
            </div>
            <div className="align-items-center p-2 my-2 text-white rounded" style={{
                display: "flex"
            }}>
                <input className="form-control me-2" type="text" value={search} placeholder="검색(업소명, 메뉴)" aria-label="Search" onChange={setSearchWord} />
                {search && <button className='clear-input' style={{ borderRadius: "20px", border: "0px", backgroundColor: "#FFFFFF" }} onClick={clearInput}><X style={{ fontSize: "17px", color: "black" }}></X></button>}
            </div>
            <div className="my-2 p-2 bg-body rounded shadow-sm">
                <StoreList storeList={storeList} isLoading={isLoading} search={search} uptaeSearch={uptaeFilter} getSelectStore={getSelectStore} viewCnt={viewCnt} setListCnt={setListCnt} lat={lat} lon={lon} getDetail={getDetail} />
                <TopButton />
            </div>
        </main>
    )
}

export default StoreListContainer;