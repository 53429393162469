import React from 'react';
import MainContainer from '../container/MainContainer';
import MainLayout from '../layout/MainLayout';

function MainPage() {
    return (
        <div>
            <MainLayout>
                <MainContainer/>
            </MainLayout>
        </div>
    );
}

export default MainPage;