import React from 'react';
import { useNavigate } from 'react-router-dom';

const DetailHeader = (props) => {
    const { store, setIndex, recommendStore, setRecommendIndex, subway, setSubwayIndex } = props;

    // 뒤로가기
    const gotoMain = () => {
        sessionStorage.setItem(`__next_from_page`,
            JSON.stringify({
                page: 'detail',
            })
        );
        const pervUrl = sessionStorage.getItem(`__current_location_url`);

        if (pervUrl) {
            window.location.replace("/" + pervUrl);
            sessionStorage.removeItem(`__current_location_url`);
        } else {
            const url = sessionStorage.getItem(`__current_url`);

            /*
            *  상세정보에 접근할 때,
            *  html 태그에 스크롤 방지를 위해 overflow: hidden이 마지막 요소에 추가된다.
            *  리스트로 돌아갈 때 다시 스크롤이 되도록 마지막 요소 제거
            */

            // 적용된 스타일 (스크롤 방지)
            const styleElements = document.querySelectorAll('style');

            // NodeList를 배열로 변환
            const styleArray = Array.from(styleElements);

            // 배열의 마지막 요소 선택
            const lastStyleElement = styleArray.pop();

            // 마지막 요소가 존재하면 삭제
            if (lastStyleElement) {
                lastStyleElement.parentNode.removeChild(lastStyleElement);
            }

            if (url === "chat") {

            } else if (url === "subway") {
                // 지하철 리스트에 대한 좌표값
                const scroll = sessionStorage.getItem(`__next_scroll_subway`);
                if (scroll) {
                    //스크롤 복원 후 저장된 위치 제거

                    const { x, y } = JSON.parse(scroll);
                    window.scrollTo(x, y);
                }

                const subwayName = sessionStorage.getItem(`__select_subway_name`);

                if (subwayName) {
                    sessionStorage.removeItem(`__select_subway_line_id`);
                    sessionStorage.removeItem(`__select_subway_name`);
                    sessionStorage.removeItem(`__next_scroll_subway`);

                    setSubwayIndex(null);
                }
            } else if (url === "camera") {
                // 보류
            } else if (url === "list") {
                const storeId = sessionStorage.getItem(`__next_scroll_Id`);
                if (storeId) {
                    const { storeNo } = JSON.parse(storeId);

                    // 상점에 대한 좌표값
                    const _scroll = sessionStorage.getItem(`__next_scroll_${storeNo}`);
                    if (_scroll) {
                        //스크롤 복원 후 저장된 위치 제거

                        const { x, y } = JSON.parse(_scroll);
                        window.scrollTo(x, y);
                    }

                    // StorePage.js에서 상세화면을 가리기 위함 
                    setIndex(null);

                    // 뒤로가기시 선택된 상점정보를 제거한다.
                    // 제거하지 않으면 리스트에서 새로고침 될 때 다시 해당 상점의 상세화면으로 전환됨
                    sessionStorage.removeItem(`__next_scroll_Id`);
                    sessionStorage.removeItem(`__next_scroll_${storeNo}`);

                }
            } else if (url === "recommend") {
                const storeId = sessionStorage.getItem(`__next_recommend_scroll_Id`);
                if (storeId) {
                    const { storeNo } = JSON.parse(storeId);

                    // 상점에 대한 좌표값
                    const _scroll = sessionStorage.getItem(`__next_recommend_scroll_${storeNo}`);
                    if (_scroll) {
                        //스크롤 복원 후 저장된 위치 제거

                        const { x, y } = JSON.parse(_scroll);
                        window.scrollTo(x, y);
                    }
                    // 상세화면을 가리기 위함
                    setRecommendIndex(null);

                    // 뒤로가기시 선택된 상점정보를 제거한다.
                    // 제거하지 않으면 리스트에서 새로고침 될 때 다시 해당 상점의 상세화면으로 전환됨
                    sessionStorage.removeItem(`__next_recommend_scroll_Id`);
                    sessionStorage.removeItem(`__next_recommend_scroll_${storeNo}`);

                }
            }
        }
    }

    return (
        <>
            <nav className="navbar navbar-expand-lg fixed-top navbar-dark" aria-label="Main navigation">
                <div className="container-fluid d-flex justify-content-between">
                    <button className='detail-item item-button left-func' onClick={gotoMain}>〈</button>
                    <span className='detail-item store-nm'>{recommendStore && recommendStore.storeNm}{store && store.storeNm}{subway && subway.stnKrNm}</span>
                    <button className='detail-item item-button right-func' onClick={() => { }}>〉</button>
                </div>
            </nav>
        </>
    )
}

export default DetailHeader
