import React from 'react';
import LoadingImg from '../../assets/loading.gif';

const Loading = () => {
  return (
    <div className="loadingBackground">
        <img src={LoadingImg} alt="로딩중" width="15%" />
        <div className="loadingText">잠시만 기다려 주세요.</div>
    </div>
  )
}

export default Loading;