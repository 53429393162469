import React, { useEffect, useState } from 'react';
import Location from '../Location';
import LocationImg from '../LocationImg';
import { CalendarCheck, Clock, GeoAltFill } from 'react-bootstrap-icons';
import Loading from '../common/Loading';
import BackButton from '../common/BackButton';

const style = {
    background: "#FFFFFF"
}
const btnStyle = {
    width: "47%"
}

const RecommendDetail = (props) => {
    const { store, isLoading, onPoint, setRecommendIndex, url } = props;
    const [locType, setLocType] = useState("");
    const [estimatedTime, setEstimatedTime] = useState("");

    const setTotalTime = (totalTime) => {
        console.log("totalTime :" + totalTime);
        setEstimatedTime(totalTime);
    }

    const clickPath = (e) => {
        console.log(e.target.value);
        setLocType(e.target.value);
        console.log(locType);
    }

    const handleClick = () => {
        const query = encodeURIComponent(`${store.storeNm.replace("&", "%26")} ${store.storeAddr.replace("  ", " ").split(" ")[0]} ${store.storeAddr.replace("  ", " ").split(" ")[1]} ${store.storeAddr.replace("  ", " ").split(" ")[2]}`);
        window.open(`https://m.search.naver.com/search.naver?sm=tab_hty.top&where=nexearch&query=${query}`, '_blank');
    };
    return (
        <>
            {isLoading && <Loading />}
            {!isLoading && store && (
                <>
                    <main className="container detail-container">
                        {store.useYn && (
                            <div className="storeImg my-3" style={{ backgroundImage: `url(${store.adImgPath})`, backgroundSize: 'cover', height: '350px', backgroundPosition: 'center center' }}></div>
                        )}
                        <div className="storeDesc">
                            <h5>{store.storeNm}</h5>
                            <button className='btn btn-primary mx-1 search-naver' onClick={handleClick}>
                                <span>상점검색</span>
                            </button>
                            {/* {store.useYn && (
                                <>
                                    <p>{store.storeDesc}</p>
                                    <hr />
                                    <p><span className="event">EVENT</span></p>
                                    <p>{store.adName}</p>
                                    <p>{store.adDesc}</p>
                                    <hr />
                                    <p><CalendarCheck size={15} /> <span>월 ~ 토</span></p>
                                    <p><Clock size={15} /> <span>18:00 ~ 02:00</span></p>
                                </>
                            )} */}
                            <span style={{
                                gridColumn: "span 2"
                            }}><GeoAltFill size={15} /> {store.storeAddr}</span>
                        </div>
                        <div className="col text-center" style={style} >
                            <button className="btn btn-primary mx-1"
                                style={btnStyle}
                                value="pedestrain"
                                onClick={clickPath}
                            >
                                만보걷기 ({estimatedTime})
                            </button>
                            <button className="btn btn-danger mx-1"
                                style={btnStyle}
                                value="drive"
                                onClick={clickPath}
                            >
                                카푸어
                            </button>
                        </div>
                        {store.useYn && (
                            <div className="storePoint">
                                <button className="shadow-sm" onClick={() => onPoint()}>적립하기</button>
                            </div>
                        )}
                        <div className='update-date'>
                            {sessionStorage.getItem(`__update_store_date`) !== null &&
                                <span>수정 일자 : {sessionStorage.getItem(`__update_store_date`)}</span>
                            }
                        </div>
                        <Location store={store} locType={locType} setTotalTime={setTotalTime} />
                        <BackButton setRecommendIndex={setRecommendIndex}/>
                    </main>
                </>
            )}
        </>
    )
}

export default RecommendDetail;