import 'bootstrap/dist/css/bootstrap.min.css';
import './offcanvas.css';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, Route, Routes } from "react-router-dom";
import MainPage from './pages/MainPage';
import StorePage from './pages/StorePage';
import PointPage from './pages/PointPage';
import RecommendPage from './pages/RecommendPage';
import SubwayPage from './pages/SubwayPage';
import AdminPage from './pages/AdminPage';
import CameraPage from './pages/CameraPage';
import ChatPage from './pages/ChatPage';
import { createBrowserHistory } from 'history';
import Header from './component/common/Header';
import Footer from './component/common/Footer';

function App() {

  const message = "위치정보를 갱신하시겠습니까?";
  const navigate = useNavigate();

  const [url, setUrl] = useState(null);
  const [address, setAddress] = useState("");     // 주소
  const [storeNo, setIndex] = useState("");         // 상점 상세화면 전환 여부
  const [subwayIndex, setSubwayIndex] = useState(""); // 지하철 상세화면 전환 여부
  const [recommendIndex, setRecommendIndex] = useState("");         // 추천 상세화면 전환 여부

  useEffect(() => {
    const date = new Date();
    // 년, 월, 일, 시간, 분, 초를 각각 추출합니다.
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // yyyy-mm-dd HH:MM:SS 형식으로 날짜를 조합합니다.
    const formattedDate = `${year}${month}${day}${hours}${minutes}${seconds}`;

    sessionStorage.setItem(`date`, formattedDate);

    navigator.geolocation.getCurrentPosition((position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      // 위치 정보는 계속 변경됨

      sessionStorage.setItem(`loc_lat`, latitude);
      sessionStorage.setItem(`loc_lon`, longitude);
    });

    console.log('현재 시간:::::::::::::::::::::::' + date.getHours());
    // 06:00 ~ 08:59
    if ((date.getHours() >= 6 && date.getMinutes() >= 0) && (date.getHours() <= 8 && date.getMinutes() <= 59)) {
      //출근시간에 세션값이 0이거나 없으면 sessionStorege 세팅
      const rushHourYn = sessionStorage.getItem(`rush_hour_cnt`);
      if (rushHourYn) {
        const { subwayCnt } = JSON.parse(rushHourYn);
        if (subwayCnt !== 1) {
          sessionStorage.setItem(
            `rush_hour_cnt`,
            JSON.stringify({
              subwayCnt: 0,
            })
          );
        }
      } else {
        sessionStorage.setItem(
          `rush_hour_cnt`,
          JSON.stringify({
            subwayCnt: 0,
          })
        );
      }
    } else {
      //출근시간이 아니면 세션 삭제
      sessionStorage.removeItem(`rush_hour_cnt`);
    }

    const rushHourSubway = sessionStorage.getItem(`rush_hour_cnt`);
    //세션값이 존재하고 subwayCnt가 0이면 지하철화면으로 이동
    if (rushHourSubway) {
      var { subwayCnt } = JSON.parse(rushHourSubway);
      if (subwayCnt === 0) {
        sessionStorage.setItem(
          `rush_hour_cnt`,
          JSON.stringify({
            subwayCnt: 1,
          })
        );
        setUrl("/subway");
      }
    }
  }, []);

  useEffect(() => {
    // 5분마다

    const interval = setInterval(() => {
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        // 위치 정보는 계속 변경됨

        let lat = sessionStorage.getItem(`loc_lat`);
        lat = parseFloat(lat.replace(/"/g, ''));
        let lon = sessionStorage.getItem(`loc_lon`);
        lon = parseFloat(lon.replace(/"/g, ''));

        if (lat !== latitude || lon !== longitude) {
          if (window.confirm(message)) {
            const url = document.location.href;
            const splitUrl = url.split('/');
            const location = splitUrl[splitUrl.length - 1];
            console.log("pathname~~~~~~~!!!!!!!!:" + location);

            // 위치 갱신시 임의로 셋팅한 위치정보 제거
            sessionStorage.removeItem(`__change_lat`);
            sessionStorage.removeItem(`__change_lon`);

            if (location === 'list' || location === 'subway') {
              window.location.replace("/" + location);
            }
          }
        }
      });
    }, 300000);

    return () => clearInterval(interval);

  }, []);

  /* ---------- 뒤로가기 감지 ---------- */
  // 1. history라는 상수에 createBrowerHistory 함수를 할당한다.
  const history = createBrowserHistory();

  // 2. custom hook에서 실행될 함수를 생성하고, 함수명을 preventGoBack으로 설정한다.
  const preventGoBack = () => {
    // 2-1. 현재 상태를 세션 히스토리 스택에 추가(push)한다.
    history.push(null, '', sessionStorage.getItem(`__current_scroll_${url}`));
  };

  // 브라우저에 렌더링 시 한 번만 실행하는 코드
  useEffect(() => {
    (() => {
      // 3. 렌더링 완료 시 현재 상태를 세션 히스토리 스택에 추가(push)한다.
      history.push(null, '', history.location.href);
      // 3-1. addEventListener를 이용해 "popstate"라는 이벤트를 감지하게 한다.
      // 3-2. popstate 이벤트를 감지했을 때 preventGoBack 함수가 실행된다.
      window.addEventListener('popstate', preventGoBack);
    })();

    // return () => {
    //   // 3-3. 렌더링이 끝난 이후엔 eventListner을 제거한다.
    //   window.removeEventListener('popstate', preventGoBack);

    //   // list에서 뒤로가기 시
    //   if (history.location.pathname === '/list') {
    //     const storeId = sessionStorage.getItem(`__next_scroll_Id`);
    //     // 상세보기 화면일 경우
    //     if (storeId) {
    //       const { storeNo } = JSON.parse(storeId);
    //       const _scroll = sessionStorage.getItem(`__next_scroll_${storeNo}`);
    //       if (_scroll) {
    //         //상점위치 스크롤 유지
    //         const { x, y } = JSON.parse(_scroll);
    //         window.scrollTo({ top: x, left: y, behavior: 'auto' });
    //       }
    //     }
    //   } else if (history.location.pathname === '/subway') {
    //     const scroll = sessionStorage.getItem(`__next_scroll_subway`);
    //     // 상세보기 화면일 경우
    //     if (scroll) {
    //       //상점위치 스크롤 유지
    //       const { x, y } = JSON.parse(scroll);
    //       window.scrollTo({ top: x, left: y, behavior: 'auto' });
    //     }
    //   }
    // };
  });

  // 4. history.location (pathname)이 변경될 때
  useEffect(() => {
    // 4-1. 현재 상태를 세션 히스토리 스택에 추가(push)한다.
    history.push(null, '', history.location.href);
  }, [history.location]);
  /* ---------- 뒤로가기 감지 끝 ---------- */

  /* ---------- 새로고침 감지 ---------- */
  // 1. custom hook으로 사용할 함수를 하나 생성한다.
  const preventClose = (e) => {
    // 2. 해당 함수 안에 새로운 함수를 생성하는데, 이때 이 함수는 자바스크립트의 이벤트를 감지하게된다.
    e.preventDefault();
    // 2-1. 특정 이벤트에 대한 사용자 에이전트 (브라우저)의 기본 동작이 실행되지 않도록 막는다.
    e.returnValue = '';
    // 2-2. e.preventDefault를 통해서 방지된 이벤트가 제대로 막혔는지 확인할 때 사용한다고 한다.
    // 2-3. 더 이상 쓰이지 않지만, chrome 설정상 필요하다고 하여 추가함.
    // 2-4. returnValue가 true일 경우 이벤트는 그대로 실행되고, false일 경우 실행되지 않는다고 한다.

  };

  // 브라우저에 렌더링 시 한 번만 실행하는 코드
  useEffect(() => {
    (() => {
      // 식음료 검색 세션 삭제
      const url = document.location.href;
      const splitUrl = url.split('/');
      const location = splitUrl[splitUrl.length - 1];

      if (location !== 'list') {
        sessionStorage.removeItem(`__next_scroll_search`);
      }

      // 카메라/주소 변경 시 다른 화면으로 전환될 때 이전 화면 세션에서 제거
      if (location !== 'camera' && location !== 'admin') {
        sessionStorage.removeItem(`__current_location_url`);
      }
      // 4. beforeunload 이벤트는 리소스가 사라지기 전 window 자체에서 발행한다.
      // 4-2. window의 이벤트를 감지하여 beforunload 이벤트 발생 시 preventClose 함수가 실행된다.
      window.addEventListener('beforeunload', preventClose);
    })();

    return () => {
      // 5. 해당 이벤트 실행 후, beforeunload를 감지하는 것을 제거한다.
      window.removeEventListener('beforeunload', preventClose);
    };
  }, []);
  /* ---------- 새로고침 감지 끝 ---------- */

  // 스와이프 감지 (작동 안되는 것 같음)
  const touchStartX = useRef(null);

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    if (touchStartX.current !== null) {
      const touchEndX = e.touches[0].clientX;
      const deltaX = touchEndX - touchStartX.current;

      if (deltaX > 10 || deltaX < -10) {
        // Cancel the touchmove event to prevent swipe
        e.preventDefault();
      }

      touchStartX.current = null;
    };
  }

  /**
   *  Footer 공용으로 사용하기 위해 위치 이동
   *  기존 : MainLayout -> Footer
   *  현재 : App -> Footer
   */

  return (
    <>
      <Header address={address} storeNo={storeNo} subwayIndex={subwayIndex} recommendIndex={recommendIndex} />
      <Routes handleTouchStart={handleTouchStart} handleTouchMove={handleTouchMove} onTouchEnd={() => { touchStartX.current = null; }}>
        <Route element={<MainPage />} path="/" />
        <Route element={<StorePage url={url} setUrl={setUrl} address={address} setAddress={setAddress} setIndex={setIndex} recommendIndex={recommendIndex} setRecommendIndex={setRecommendIndex} storeNo={storeNo} setSubwayIndex={setSubwayIndex} subwayIndex={subwayIndex} />} path="/list" />
        <Route element={<PointPage />} path="/point" />
        {/* <Route element={<RecommendPage />} path="/recommend" /> */}
        {/* <Route element={<SubwayPage />} path="/subway" /> */}
        <Route element={<AdminPage />} path="/admin" />
        <Route element={<CameraPage />} path="/camera" />
        {/* <Route element={<ChatPage />} path="/chat" /> */}
      </Routes>
      <Footer setUrl={setUrl} />
    </>
  );
}

export default App;