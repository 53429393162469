import React, { useRef, useState } from 'react';
import Loading from '../component/common/Loading';
import axios from 'axios';
import BackButton from '../component/common/BackButton';
import heic2any from 'heic2any';

function CameraContainer() {
    const [isLoading, setIsLoading] = useState(false);
    const [imgFile, setImgFile] = useState(null);
    const [result, setResult] = useState(null);
    const fileInputRef = useRef(null);

    const handleFileChange = async (e) => {
        const file = e.target.files[0];

        if (file) {
            try {
                // FileReader 객체 생성
                const reader = new FileReader();

                // 파일을 읽어와서 변환 작업 수행
                reader.onload = async () => {
                    const result = reader.result;
                    // HEIC/HEIF 파일인 경우, HEIC를 JPEG로 변환 후 Data URL로 저장
                    if (file.type === 'image/heic' || file.type === 'image/heif') {
                        try {
                            const jpegBlob = await heic2any({ blob: file, toType: 'image/jpeg' });
                            const reader = new FileReader();
                            reader.readAsDataURL(jpegBlob);
                            reader.onload = () => {
                                setImgFile(reader.result);
                            };
                        } catch (error) {
                            console.error('Error converting HEIC to JPEG:', error);
                        }
                    } else {
                        setImgFile(result);
                    }
                };

                // FileReader를 사용하여 파일 읽기 시작
                reader.readAsDataURL(file);
            } catch (error) {
                console.error('Error reading file:', error);
            }
        }
    }

    const handleCustomButtonClick = () => {
        fileInputRef.current.click(); // 파일 선택 버튼 클릭
    };

    const sendSenti = async () => {
        // 이미지 파일이 없는 경우 처리
        if (!imgFile) {
            alert('이미지를 선택해주세요.');
            return;
        }

        try {
            setIsLoading(true);
            const formData = new FormData();
            formData.append('image', dataURItoBlob(imgFile)); // Base64 데이터를 Blob으로 변환하여 FormData에 추가

            // 서버로 이미지 데이터 전송
            const response = await axios.post(`https://acc.tsware.dev/acc/store/imgsenti`, formData, {
            // const response = await axios.post(`http://localhost:3000/acc/store/imgsenti`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setResult(response.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error sending image:', error);
        }
    }

    // Base64 데이터를 Blob으로 변환하는 함수
    const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };

    return (
        <div style={{
            textAlign: "center"
        }}>
            {isLoading && (
                <Loading />
            )}
            <button onClick={handleCustomButtonClick} style={{
                margin: "20px"
            }}>사진찍기</button>
            <input type="file" ref={fileInputRef} onChange={handleFileChange} accept="*" capture="camera" />
            <br />
            {imgFile && <div style={{
                marginBottom: "100px",
                display: "grid",
                justifyItems: "center"
            }}>
                {<img src={imgFile} style={{
                    width: '80%'
                }} />}
                <br />
                {imgFile && <button onClick={sendSenti}>전송</button>}
                {result}
            </div>}
            <BackButton setIndex={null} />
        </div>
    )
}

export default CameraContainer;