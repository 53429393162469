import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Loading from '../common/Loading';
import StoreItem from './StoreItem';

function StoreList(props) {

    const { storeList, search, isLoading, uptaeSearch, viewCnt, lat, lon } = props;
    const { setListCnt, getDetail, getSelectStore } = props;
    const [ref, inView] = useInView();
    const [cnt, setCnt] = useState(50);
    let [storeId, setStoreId] = useState("");

    console.log("cnt:" + cnt);

    const filtered = storeList.filter((store) => {
        const selectStore = sessionStorage.getItem(`__select_store_No`);
        const fromPage = sessionStorage.getItem(`__next_from_page`);

        //상세화면에서 호출되지 않은것은 클릭 이력 초기화
        if (fromPage === null && selectStore !== null) {
            const { selectStoreNo } = JSON.parse(selectStore);
            if (store.storeNo === selectStoreNo) {
                storeId = '';
                //sessionStorage.removeItem( `__select_store_No`);
            }
        }

        return store.sntUptaeNm.includes(uptaeSearch) && (store.storeNm.includes(search) || (store.crawlMenu !== null ? store.crawlMenu.includes(search) : null));
    });

    // 상점번호를 상점상세로 전달하기 위함
    const selectStore = (storeNo) => {
        console.log("LIST :" + storeNo);
        getSelectStore(storeNo);

    }

    // 상점 상세정보 전달하
    const setDetail = (response) => {
        getDetail(response);
        const scrollId = sessionStorage.getItem(`__next_scroll_Id`);
        if (scrollId) {
            const { storeNo } = JSON.parse(scrollId);
            // 선택된 리스트 음영처리
            setStoreId(storeNo);
        }
    }

    useEffect(() => {
        if (isLoading) {
            // 새로 로딩되면 카운트 초기화
            setCnt(50);
            if (viewCnt === 50) {
                setListCnt(50);
            }
            console.log(viewCnt);
        }
        if (inView) {
            // 마지막 항목이면 50개 더 가져오기
            setCnt(cnt + 50);
            setListCnt(cnt + 50);
            console.log('cnt', cnt);
        } else {
            if (viewCnt !== cnt) {
                setCnt(viewCnt);
            }
        }
        const scrollId = sessionStorage.getItem(`__next_scroll_Id`);
        if (scrollId) {
            console.log(scrollId);
            const { storeNo } = JSON.parse(scrollId)
            setStoreId(storeNo);
        }
    }, [inView, isLoading]);

    // 검색어 하이라이트 처리
    function highlight(text, searchTerm) {
        // 정규 표현식에 사용되는 특수 문자를 이스케이프하여 처리
        const escapeRegExp = (string) => {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        };

        // 검색어를 이스케이프하여 처리
        const escapedSearchTerm = escapeRegExp(searchTerm);

        // 이스케이프된 검색어를 사용하여 대소문자를 구분하지 않는 정규 표현식을 생성
        const regex = new RegExp(escapedSearchTerm, 'gi');

        // 검색어가 빈 문자열인 경우 텍스트를 그대로 반환하고, 아닌 경우에는 검색어를 강조 표시
        if (searchTerm === '') {
            return text; // 검색어가 없으면 텍스트를 그대로 반환
        } else {
            return text.replace(
                regex, // 생성된 정규 표현식을 사용하여 검색어를 강조 표시
                match => {
                    return `<span style="border: 2px solid red; margin: 0px">${match}</span>`; // 강조 표시된 검색어를 스타일링하여 반환
                }
            );
        }
    }

    return (
        <>
            {isLoading && (
                <Loading />
            )}
            {
                !isLoading && storeList && (

                    <div id="storeList">
                        {
                            filtered.map((store, index) => {
                                const highlightedMenu = highlight(store.storeNm, search);
                                return (
                                    index < cnt &&
                                    <div key={store.storeNo} ref={(index + 1) === cnt ? ref : null} className={'d-flex text-muted pt-1 ' + (store.adYn === 'Y' ? 'ad' : '')}>
                                        <StoreItem store={store} setDetail={setDetail} selectStore={selectStore} lat={lat} lon={lon} highlightedMenu={highlightedMenu} highlight={highlight} search={search} />
                                    </div>
                                )
                            })
                        }
                    </div>
                )}
            {!isLoading && storeList.length === 0 && (
                <div id="storeList">
                    <div className='text-muted text-center pt-1 '>
                        서비스 가능 지역이 아닙니다.
                    </div>
                </div>
            )}
        </>
    )
}

export default StoreList;