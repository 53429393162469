import React from 'react';
import { useEffect, useState } from 'react';
//import { GeoAltFill} from 'react-bootstrap-icons';
import logo from './../../assets/adcashcow.png';
import Weather from '../Weather';

const Header = ({ address, storeNo, subwayIndex, recommendIndex }) => {

    const [isHeader, setIsHeader] = useState(true);
    const [newAddress, setNewAddress] = useState(address);
    const [clickCount, setClickCount] = useState(1);

    useEffect(() => {  
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isHeader]);

    useEffect(() => {
        if (address !== null && typeof address !== 'undefined') {
            // console.log(address.indexOf(' '));
            var startInx = address.indexOf(' ');
            setNewAddress(address.substring(startInx, address.length));
        } else {

        }
        // console.log("newAddress:::::::::::::" + newAddress)
    });

    const handleScroll = () => {
        if (window.scrollY > 300 && isHeader) {
            // 특정 스크롤 이상 내려가면 헤더 숨기기
            setIsHeader(false);
            return;
        }
        if (window.scrollY < 300 && !isHeader) {
            setIsHeader(true);
            return;
        }
    };


    const onClickHome = () => {
        sessionStorage.clear();

    };

    const adminPage = (e) => {
        setClickCount(clickCount + 1);
        console.log(clickCount);
        if (clickCount == 5) {
            const url = document.location.href;
            const splitUrl = url.split('/');
            const location = splitUrl[splitUrl.length - 1];
            sessionStorage.setItem(`__current_location_url`, location);
            window.location.replace("/admin");
        }
    }
    
    return (
        <>
            <nav className={"navbar navbar-expand-lg navbar-dark " + (!isHeader ? 'navbar-off ' : ' ') + ((storeNo || subwayIndex || recommendIndex) ? 'minus-index' : 'fixed-top')} aria-label="Main navigation">
                <div className="container-fluid">
                    {/* <a className="navbar-brand" href="/"><img src={logo} width="30px" />애드캐시카우</a> */}
                    <a className="navbar-brand" onClick={onClickHome} href="/">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span>
                                ADcash
                            </span>
                            <span>
                                <img src={logo} alt="ADcash 로고" width="30px" style={{ marginRight: '5px' }} />
                            </span>
                        </div>
                        <div style={{ fontSize: '8px' }}>
                            애드캐시카우
                        </div>
                        {/* ADcash<img src={logo} width="30px" /> */}
                    </a>
                    <Weather address={newAddress} />
                    <h1 className="h6 mb-0 text-white lh-1">
                        {/* <GeoAltFill size={25} /> */}
                        <span className='location' onClick={adminPage}>{newAddress}</span>
                    </h1>
                </div>
            </nav>
        </>
    )
}

export default Header;