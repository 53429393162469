import React, { useEffect, useState } from 'react'
import { motion, AnimatePresence } from "framer-motion";
import dayjs from 'dayjs';
import { ChevronDoubleUp, ChevronDoubleDown } from 'react-bootstrap-icons';
import RecommendSubContent from './RecommendSubContent';

let style = {
    color: "#c7254e"
}
let dateStyle = {
    color: "#008000"
}
let reviewStyle = {
    color: "#000000",
    font: ""
}
let gradeStyle = {
    color: "#FF0000"
}

function RecommendSubItem(props) {

    const { store, lat, lon, parentId/*, setParentOpen*/ } = props;  // 전달된 값
    const { selectStore, setDetail } = props;  // 전달 해야할 값

    // 리스트 클릭 이벤트

    const [isOpen, setIsOpen] = useState(false);
    const [isIndexOpen, setIsIndexOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
        setIsIndexOpen(!isIndexOpen);
    }

    // useEffect(() => {
    //     debugger;
    //     const selectStore = sessionStorage.getItem(`__select_recommend_No`);
    //     if (selectStore) {
    //         const { selectStoreNo } = JSON.parse(selectStore);

    //         if (selectStoreNo == store.storeNo) {
    //             toggleOpen();
    //             setParentOpen(true);
    //         }
    //     }
    // }, []);

    return (
        <AnimatePresence>
            <motion.div className={"pb-1 mb-0 small lh-sm border-bottom w-100 recommend-bottom-color " + (isOpen ? 'selectStore' : 'text-primary')} onClick={toggleOpen}>
                <motion.div key="content" className={'d-flex justify-content-between '} layout style={{
                    position: 'relative',
                    alignItems: 'center',
                    fontWeight: "bold"
                }}>
                    <motion.span>
                        {store.adYn && <span className="text-dark-gray"><font>광고</font> {store.storeNm}</span>}
                        {!store.adYn && <>
                            <span className='text-primary'>
                                <span style={style}>[{store.sntUptaeNm}]</span>
                                <span style={dateStyle}>[{dayjs((store.bmanStdt) === true ? store.bmanStdt : store.permNtYmd).format('YYYY.MM')}~]</span>
                                {/* {!(parentId === '신규 상점' || parentId === '오래된 상점') && <span style={reviewStyle}>리뷰수:{store.crawlTotalReviewCnt}</span>} */}
                                <br />{/*{store.crawlGrade && <span style={gradeStyle}><StarFill /> {store.crawlGrade}</span>}*/}<span>{store.storeNm}</span>
                            </span>
                            {/* 리뷰 점수 임시 */}
                            {/* <span className='text-primary'>
                                <span style={style}>[{store.sntUptaeNm}]</span>
                                <span style={dateStyle}>[{dayjs((store.bmanStdt) === true ? store.bmanStdt : store.permNtYmd).format('YYYY.MM')}~]</span>
                                {!(parentId === '신규 상점' || parentId === '오래된 상점') && <span style={reviewStyle}>
                                    <br/>리뷰수:{store.crawlTotalReviewCnt}({store.highestSentiRslt} / {store.lowestSentiRslt})
                                </span>}
                                <br /><span>{store.storeNm}</span>
                            </span> */}
                        </>}
                    </motion.span>
                    <motion.span style={{
                        textAlign: "right"
                    }}>
                        <div className='text-primary'>{store.distance}m</div>
                        {!isOpen &&
                            <div style={{
                                fontSize: "18px"
                            }}>{(store.crawlTel || store.crawlOpen || store.crawlMenu) ? <ChevronDoubleDown style={{ color: "#FF5500" }} /> : <ChevronDoubleDown style={{ color: "gray" }} />}</div>
                        }
                        {isOpen &&
                            <div style={{
                                fontSize: "18px"
                            }}>{(store.crawlTel || store.crawlOpen || store.crawlMenu) ? <ChevronDoubleUp style={{ color: "#FF5500" }} /> : <ChevronDoubleUp style={{ color: "gray" }} />}</div>
                        }
                    </motion.span>
                    {parentId === '딥러닝 리뷰 감정분석' && <div style={{
                        position: 'absolute',
                        color: 'rgba(255, 0, 0, 0.1)',
                        width: '100%',
                        textAlign: 'center',
                        fontSize: '40px'
                    }}><span>{store.crawlAvgReivewSentiRslt}</span></div>}
                </motion.div>
                {store.crawlImg !== null &&
                    <motion.div style={{ display: "flex", flexWrap: "wrap" }}>
                        {store.crawlImg.map((imageUrl, idx) => {
                            return (
                                <div key={idx} style={{ flexBasis: "25%", overflow: "hidden" }}>
                                    <div style={{ width: "100px", height: "100px" }}>
                                        <img src={imageUrl} loading="lazy" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                                    </div>
                                </div>
                            );
                        })}
                    </motion.div>
                }
                {parentId === '딥러닝 리뷰 감정분석' && <motion.div style={{ fontWeight: "bold" }}>
                    <span style={{
                        color: "red"
                    }}>최고점수 : {store.highestSentiRslt}</span><br />
                    <span style={{
                        color: "black"
                    }}>{store.highestSentiRsltText}</span><br /><br />
                    <span style={{
                        color: "blue"
                    }}>최저점수 : {store.lowestSentiRslt}</span><br />
                    <span style={{
                        color: "black"
                    }}>{store.lowestSentiRsltText}</span>
                </motion.div>}
                <AnimatePresence>
                    {isOpen && <RecommendSubContent store={store} lat={lat} lon={lon} selectStore={selectStore} setDetail={setDetail} isOpen={isOpen} isIndexOpen={isIndexOpen} />}
                </AnimatePresence>
            </motion.div>
        </AnimatePresence>
    );
}

export default RecommendSubItem;