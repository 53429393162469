import React, { useEffect, useState } from 'react'
import { motion, AnimatePresence } from "framer-motion";
import dayjs from 'dayjs';
import { ChevronDoubleDown, ChevronDoubleUp } from 'react-bootstrap-icons';
import Content from './StoreContent';
import dompurify from "dompurify";

let style = {
    color: "#c7254e"
}
let dateStyle = {
    color: "#008000"
}

function StoreItem(props) {

    const { store, lat, lon, highlightedMenu, highlight, search } = props;  // 전달된 값
    const { selectStore, setDetail } = props;  // 전달 해야할 값

    // 리스트 클릭 이벤트
    const [isOpen, setIsOpen] = useState(false);
    const [isIndexOpen, setIsIndexOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
        setIsIndexOpen(!isIndexOpen);
    }

    useEffect(() => {
        const selectStore = sessionStorage.getItem(`__select_store_No`);
        if (selectStore) {
            const { selectStoreNo } = JSON.parse(selectStore);

            if (selectStoreNo == store.storeNo) {
                toggleOpen();
            }
        }
    }, []);

    const sanitizer = dompurify.sanitize;

    return (
        <AnimatePresence>
            <motion.div className="pb-1 mb-0 small lh-sm border-bottom w-100" onClick={toggleOpen}>
                <motion.div key="content" className={'d-flex justify-content-between ' + (isOpen ? 'selectStore' : 'text-primary')} layout>
                    <motion.span style={{
                        lineHeight: "20px"
                    }}>
                        {store.adYn && <span className="text-dark-gray"><font>광고</font> {store.storeNm}</span>}
                        {!store.adYn && <>
                            <span className='text-primary' >
                                <span style={style}>[{store.sntUptaeNm}]</span>
                                <span style={dateStyle}>[{dayjs((store.bmanStdt) === true ? store.bmanStdt : store.permNtYmd).format('YYYY.MM')}~]</span>
                                <br />
                            </span>
                            <span className='text-primary' dangerouslySetInnerHTML={{ __html: sanitizer(highlightedMenu) }}></span>
                        </>}
                    </motion.span>
                    <motion.span style={{
                        textAlign: "right"
                    }}>
                        <div className='text-primary'>{store.distance}m</div>
                        {!isOpen &&
                            <div style={{
                                fontSize: "18px"
                            }}>{(store.crawlTel || store.crawlOpen || store.crawlMenu) ? <ChevronDoubleDown style={{ color: "#FF5500", display: "inline" }} /> : <ChevronDoubleDown style={{ color: "gray", display: "inline" }} />}</div>
                        }
                        {isOpen &&
                            <div style={{
                                fontSize: "18px"
                            }}>{(store.crawlTel || store.crawlOpen || store.crawlMenu) ? <ChevronDoubleUp style={{ color: "#FF5500", display: "inline" }} /> : <ChevronDoubleUp style={{ color: "gray", display: "inline" }} />}</div>
                        }
                    </motion.span>
                </motion.div>
                {/* {store.crawlImgUrl !== null &&
                    <motion.div style={{ display: "flex", flexWrap: "wrap" }}>
                        {store.crawlImgUrl.map((imageUrl, idx) => {
                            return (
                                <div key={idx} style={{ flexBasis: "25%", overflow: "hidden" }}>
                                    <div style={{ width: "100px", height: "100px" }}>
                                        <img src={imageUrl} loading="lazy" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                                    </div>
                                </div>
                            );
                        })}
                    </motion.div>
                } */}
                {store.crawlImg !== null && (
                    <motion.div style={{ display: "flex", flexWrap: "wrap" }}>
                        {store.crawlImg.map((imageUrl, idx) => (
                            <div key={idx} style={{ flexBasis: "25%", overflow: "hidden" }}>
                                <div style={{ width: "100px", height: "100px" }}>
                                    <img
                                        src={imageUrl}
                                        loading="lazy"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                    </motion.div>
                )}
                <AnimatePresence>
                    {isOpen && <Content store={store} lat={lat} lon={lon} selectStore={selectStore} setDetail={setDetail} isIndexOpen={isIndexOpen} isOpen={isOpen} highlight={highlight} search={search} />}
                </AnimatePresence>
            </motion.div>
        </AnimatePresence>
    );
}

export default StoreItem;