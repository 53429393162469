import React, { useEffect, useState } from 'react';
// import { useParams, useLocation } from 'react-router-dom';
import StoreDetail from '../component/store/StoreDetail';
import * as client from '../lib/api';
// import queryString from 'query-string';

const StoreDetailContainer = (props) => {
    // const {storeNo, adYn} = useParams();
    // const { search } = useLocation();
    // const { storeNo, adYn, lat, lon } =  queryString.parse(search);

    const { storeNo, lat, lon, setIndex, detail } = props;
    const [store, setStore] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const readStore = async (storeNo, address, lat, lon) => {
        // 로딩 처리
        setIsLoading(true);

        // 부모 스크롤 제거
        const styleElement = document.createElement('style');
        styleElement.textContent = 'html { overflow: hidden; }';
        document.head.appendChild(styleElement);

        try {
            // 상세화면 접근 시 리스트에서 접속하는 경우 (상점 상세정보 값이 있을 때)
            if (detail !== "") {
                setStore(detail.data);
                props.setStore(detail.data);
            } else {
                // 상세화면에서 새로고침 시 상점정보 재조회
                // 리스트 클릭할 때 상점 상세를 가져오는데 새로고침 하면 상세정보가 사라지므로 재조회
                const response = await client.fetchStore(storeNo, address, "N", lat, lon);
                setStore(response.data);
                props.setStore(response.data);

            }

            // 로딩 제거
            setIsLoading(false);
        } catch (e) {
            throw e;
        }
    };

    const onPoint = async () => {
        alert("포인트 적립 중")

        try {
            const response = await client.fetchPointAdd(store.adNo, store.pmtNo, store.userId, "devoh@tsware.co.kr", "36.5", "128.4", store.advrtNo);
            console.log(response);
        } catch (e) {
            alert(e.response.data);
        }
    };

    // 좌표값이 변경될 때
    useEffect(() => {
        if (lat !== "" && lon !== "") {
            const address = sessionStorage.getItem(`str_addr`);
            readStore(storeNo, address, lat, lon);
        }
    }, [lat, lon]);

    return (<StoreDetail storeNo={storeNo} store={store} isLoading={isLoading} onPoint={onPoint} setIndex={setIndex} />);
}

export default StoreDetailContainer;