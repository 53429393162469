import React, { useEffect, useState } from 'react'
import { CloudSleet, CloudRain, CloudSnow } from 'react-bootstrap-icons';
import * as client from '../lib/api';


const Weather = (props) => {

    const { address } = props;
    const [weatherInfo, setWeatherInfo] = useState([]);
    const [temp, setTemp] = useState("");
    const [weatherCode, setWeatherCode] = useState("");
    const [clickCount, setClickCount] = useState(1);

    const searchWeatherInfo = async (lat, lon) => {

        try {
            const response = await client.fetchWeather(lat, lon);
            console.log(response);
            response.data.map((items, index) => {
                if (items.category === 'T1H') {
                    setTemp(items.obsrValue + "º");
                    sessionStorage.setItem(`temp`, items.obsrValue);
                }
                if (items.category === 'PTY') {
                    setWeatherCode(items.obsrValue);
                }
            });
            //setWeatherInfo(response.data);
            //console.log(weatherInfo);

        } catch (e) {
            console.log(e);
            throw e;
        }

        weatherInfo.map((items, index) => {
            if (items.category === 'T1H') {
                setTemp(items.obsrValue + "º");
            }
            if (items.category === 'PTY') {
                setWeatherCode(items.obsrValue);
            }
        });

    };

    useEffect(() => {
        console.log('현재 위치 구하기');
        // 현재 위치 좌표 구해서, 가게 목록 조회
        if (address !== "") {
            navigator.geolocation.getCurrentPosition((position) => {
                var lat = position.coords.latitude;
                var lon = position.coords.longitude;
                console.log('lat', lat);
                console.log('lon', lon);
                searchWeatherInfo(lat, lon);
            })
        }

    }, [address]);

    // 3번 클릭시 카메라 화면 전환
    const cameraPage = (e) => {
        setClickCount(clickCount + 1);
        console.log(clickCount);
        const prevUrl = sessionStorage.getItem(`__current_location_url`);
        if (clickCount == 3 && prevUrl === null) {
            const url = document.location.href;
            const splitUrl = url.split('/');
            const location = splitUrl[splitUrl.length - 1];
            sessionStorage.setItem(`__current_location_url`, location);
            window.location.replace("/camera");
        }
    }

    return (
        <>
            {weatherInfo && (
                <div className="mb-0 text-white lh-1" >
                    <h2 onClick={cameraPage}>
                        {weatherCode === "1" ? (<CloudRain size={30} />)
                            : (weatherCode === "2" ? (<CloudSleet size={30} />) :
                                (weatherCode === "3" ? (<CloudSnow size={30} />) : ""))
                        }
                        {temp}
                    </h2>
                </div>

            )}

        </>
    )
}

export default Weather;
