import React from 'react';
import PointItem from './PointItem';

function PointList(props) {
    
    const { pointList } = props;

    const totalPoint = pointList.reduce(function(sum, point) {
        return sum + parseInt(point.earnedPoint);
    }, 0);

    return (
        <>
            <div className='totalPoint'>
                <div>
                    <p>{totalPoint}원</p>
                </div>
                <div>
                    <button onClick={() => alert("포인트 전환 준비 중")}>포인트 전환</button>
                    <button onClick={() => alert("포인트 선물 준비 중")}>포인트 선물</button>
                </div>
            </div>
            <div className='history'>
                {
                    pointList.map((point, index) => {
                        return (
                            <PointItem key={index} point={point} />
                        )
                    })
                }
            </div>
        </>
    )
}

export default PointList;