// import React, { useEffect, useState, useRef } from 'react';
// import { CardList, AppIndicator, TrainFront, ChatDots } from 'react-bootstrap-icons';
// import { Link, NavLink } from 'react-router-dom';

// function Footer() {
//     return (
//         <footer className={"footer mt-auto py-2 bottom-0 end-0 start-0 " + ((JSON.parse(sessionStorage.getItem(`__next_scroll_Id`)) || (sessionStorage.getItem(`__next_scroll_subway`))) ? '' : 'position-fixed')}>
//             <div className="container d-flex justify-content-around">
//                 <NavLink to="/list" className={({isActive}) => (isActive? "active" : "")}>
//                     <div className="d-flex flex-column align-items-center">
//                         <CardList size={25} />
//                         <span>식음료</span>
//                     </div>
//                 </NavLink>
//                 <NavLink to="/recommend" className={({isActive}) => (isActive? "active" : "")}>
//                     <div className="d-flex flex-column align-items-center">
//                         <AppIndicator size={25} />
//                         <span>추천</span>
//                     </div>
//                 </NavLink>
//                 <NavLink to="/chat" className={({isActive}) => (isActive? "active" : "")}>
//                     <div className="d-flex flex-column align-items-center">
//                         <ChatDots size={25} />
//                         <span>Ts LLM 챗봇</span>
//                     </div>
//                 </NavLink>
//                 {/* <button onClick={handleCustomButtonClick} className='camera'>
//                     <div className="d-flex flex-column align-items-center">
//                         <input type="file" ref={fileInputRef} onChange={handleFileChange} accept="image/*" capture="camera" />
//                         <Camera size={25} />
//                         <span>카메라</span>
//                     </div>
//                 </button> */}
//                 <NavLink to="/list" className={({isActive}) => (isActive? "active" : "")}>
//                     <div className="d-flex flex-column align-items-center">
//                         <TrainFront size={25} />
//                         <span>지하철</span>
//                     </div>
//                 </NavLink>
//             </div>
//         </footer>
//     )
// }
// 
// export default Footer;

import React, { useState, useEffect } from 'react';
import { CardList, AppIndicator, TrainFront, ChatDots } from 'react-bootstrap-icons';

function Footer(props) {
    const { setUrl } = props;
    const [isActive1, setIsActive1] = useState(false);
    const [isActive2, setIsActive2] = useState(false);
    const [isActive3, setIsActive3] = useState(false);
    const [isActive4, setIsActive4] = useState(false);

    const setScroll = (currentUrl) => {
        const current = sessionStorage.getItem(`__current_url`);

        sessionStorage.setItem(
            `__current_scroll_${current}`,
            JSON.stringify({
                x: window.pageXOffset,
                y: window.pageYOffset,
            })
        );

        sessionStorage.setItem(`__current_url`, currentUrl);
    };

    /**
     *  각 버튼 마다 useState를 적용하여 버튼 클릭 시 className 토글 하여 색상 변경
     */
    // 상점
    const handleButtonClick1 = () => {
        setIsActive1(true);
        setIsActive2(false);
        setIsActive3(false);
        setIsActive4(false);

        setScroll("list");

        // 버튼 클릭 시 '/list' 경로로 이동
        setUrl("list");
    };

    // 추천
    const handleButtonClick2 = () => {
        setIsActive1(false);
        setIsActive2(true);
        setIsActive3(false);
        setIsActive4(false);

        setScroll("recommend");

        // 버튼 클릭 시 '/list' 경로로 이동
        setUrl("recommend");
    };

    // llm 챗
    const handleButtonClick3 = () => {
        setIsActive1(false);
        setIsActive2(false);
        setIsActive3(true);
        setIsActive4(false);

        setScroll("chat");

        // 버튼 클릭 시 '/list' 경로로 이동
        setUrl("chat");
    };

    // 지하철
    const handleButtonClick4 = () => {
        setIsActive1(false);
        setIsActive2(false);
        setIsActive3(false);
        setIsActive4(true);

        setScroll("subway");

        // 버튼 클릭 시 '/list' 경로로 이동
        setUrl("subway");
    };

    useEffect(() => {
        // 화면 처음 진입시 버튼 색상 활성화
        const currentUrl = sessionStorage.getItem(`__current_url`);
        
        if (currentUrl === "list") {
            handleButtonClick1();
        } else if (currentUrl === "recommend") {
            handleButtonClick2();
        } else if (currentUrl === "chat") {
            // 채팅의 경우 식음료페이지가 호출되어야하므로 리스트 페이지로 이동
            handleButtonClick1();
        } else if (currentUrl === "subway") {
            handleButtonClick4();
        } else {
            // 선택된 페이지가 없는 경우에는 출퇴근 시간 적용
            const rushHourSubway = sessionStorage.getItem(`rush_hour_cnt`);
            if (rushHourSubway) {
                setIsActive4(true);
                sessionStorage.setItem(
                    `__current_scroll_subway`,
                    JSON.stringify({
                        x: window.pageXOffset,
                        y: window.pageYOffset,
                    })
                );
                sessionStorage.setItem(`__current_url`, 'subway');
                setUrl("subway");
            } else {
                setIsActive1(true);
                sessionStorage.setItem(
                    `__current_scroll_list`,
                    JSON.stringify({
                        x: window.pageXOffset,
                        y: window.pageYOffset,
                    })
                );
                sessionStorage.setItem(`__current_url`, 'list');
                setUrl("list");
            }
        }
    }, []);

    return (
        <footer className={"footer mt-auto py-2 bottom-0 end-0 start-0 " + ((JSON.parse(sessionStorage.getItem(`__next_scroll_Id`)) || (sessionStorage.getItem(`__next_scroll_subway`))) ? '' : 'position-fixed')}>
            <div className="container d-flex justify-content-around">
                <div className={`${isActive1 ? 'active' : 'inactive'}`} onClick={handleButtonClick1}>
                    <div className="d-flex flex-column align-items-center">
                        <CardList size={25} />
                        <span>식음료</span>
                    </div>
                </div>
                <div className={`${isActive2 ? 'active' : 'inactive'}`} onClick={handleButtonClick2}>
                    <div className="d-flex flex-column align-items-center">
                        <AppIndicator size={25} />
                        <span>추천</span>
                    </div>
                </div>
                <div className={`${isActive3 ? 'active' : 'inactive'}`} onClick={handleButtonClick3}>
                    <div className="d-flex flex-column align-items-center">
                        <ChatDots size={25} />
                        <span>Ts LLM 챗봇</span>
                    </div>
                </div>
                <div className={`${isActive4 ? 'active' : 'inactive'}`} onClick={handleButtonClick4}>
                    <div className="d-flex flex-column align-items-center">
                        <TrainFront size={25} />
                        <span>지하철</span>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;