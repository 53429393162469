import React from 'react'

function PointItem(props) {

    const {point} = props;

    const dateFormat = (dttm) => {
        var format = new Date(dttm);
        return (format.getMonth() + 1) + "." + format.getDate();
    }

    return (
        <div>
            <div><span>{dateFormat(point.regDttm)}</span><span>{point.storeNm}</span></div>
            <div><span>{point.earnedPoint}원</span></div>
        </div>
    )
}

export default PointItem;