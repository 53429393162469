import React, {useEffect} from 'react';

function MainContainer() {

  useEffect(() => {
    const date = new Date();

    console.log('현재 시간:::::::::::::::::::::::'+date.getHours());
    if((date.getHours() >= 6 && date.getMinutes() >= 0) && (date.getHours() <= 8 && date.getMinutes() <= 59) ){
      //출근시간에 세션값이 0이거나 없으면 sessionStorege 세팅
      window.location.replace("/subway");
    }else{
      //출근시간이 아니면 스토어
      window.location.replace("/list");
    }
  }, []);

  return (
    <>
    </>
  )
}

export default MainContainer;