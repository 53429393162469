import axios from 'axios';

export const fetchStoreList = (lat, lon) => axios.get(`/acc/store/list?lat=${lat}&lon=${lon}`);
export const fetchStore = (storeNo, address, adYn, lat, lon) => axios.get(`/acc/store/detail?storeNo=${storeNo}&address=${address}&adYn=${adYn}&lat=${lat}&lon=${lon}`);
export const fetchStoreImage = (query) => axios.get(`/acc/store/image?query=${query}`);
export const fetchPointList = (cstmrUserId) => axios.get(`/acc/point/list?cstmrUserId=${cstmrUserId}`);
export const fetchPointAdd = (adNo, pmtNo, advrtUserId, cstmrUserId, adReadLocLat, adReadLocLon, advrtNo) => axios.post("/acc/store/savehist", {adNo, pmtNo, advrtUserId, cstmrUserId, adReadLocLat, adReadLocLon, advrtNo});
export const fetchPolyLine = (myLocation, location) => axios.get(`/acc/store/driving?startLon=${myLocation._lng}&startLat=${myLocation._lat}&endLon=${location._lng}&endLat=${location._lat}`);
export const fetchPedestrain = (myLocation, location) => axios.get(`/acc/store/pedestrain?startLon=${myLocation._lng}&startLat=${myLocation._lat}&endLon=${location._lng}&endLat=${location._lat}`);
export const fetchWeather = (lat, lon) => axios.get(`/acc/store/weather?lat=${lat}&lon=${lon}`);
export const fetchSubwayList = (lat, lon) => axios.get(`/acc/subway/list?lat=${lat}&lon=${lon}`);
export const fetchSubway = (subwayNo,lat,lon) => axios.get(`/acc/subway/map?subwayNo=${subwayNo}&lat=${lat}&lon=${lon}`);
export const fetchSubwayArrival = (stnKrNm, lineId) => axios.get(`/acc/subway/arrival?station=${stnKrNm}&lineId=${lineId}`);
export const fetchSubwayTime = (outStnNum) => axios.get(`/acc/subway/timetable?stationNum=${outStnNum}`);
export const fetchCongestionTrain = (subwayLine, trainNo) => axios.get(`/acc/subway/congestion?subwayLine=${subwayLine}&trainNo=${trainNo}`);
export const fetchSave = (json) => axios.post(`/acc/store/save`, json);
// export const fetchCrawlSelect = (storeNo) => axios.get(`/acc/store/crawl?storeNo=${storeNo}`);
export const fetchCoordinate = (address) => axios.get(`/acc/store/coordinate?address=${address}`);
export const fetchRecommendList = (lat, lon) => axios.get(`/acc/store/recommend?lat=${lat}&lon=${lon}`);
export const openTMapPage = (lat, lon, storeNm) => axios.get(`/acc/store/tmap?lat=${lat}&lon=${lon}&storeNm=${storeNm}`);
export const fetchChat = (json) => axios.post(`/acc/store/chat`, json);
//export const fetchPolyLine2 = (myLocation, location, headers) => axios.get(`/map-direction/v1/driving?start=${myLocation._lng},${myLocation._lat}&goal=${location._lng},${location._lat}`, {headers});
//export const fetchStoreImage2 = (query, headers) => axios.get(`/v1/search/image?query=${query}&display=10&start=1&sort=sim`, {headers});
// export const fetchImageSenti = (imgUrl) => axios.post(`/acc/store/imgsenti`, imgUrl);