import React from 'react';
import SubwayItem from './SubwayItem';
import Loading from '../common/Loading';

function SubwayList(props) {

    const { subwayList, isLoading, lat, lon } = props;
    const { setStatnFNm, setStatnTNm, setSubwayNo, setOutStnNum, setSubwayIndex } = props;

    return (
        <>
            {isLoading && (
                <Loading />
            )}
            {
                !isLoading && subwayList && (

                    <div id="subwayList">
                        {
                            subwayList.map((subway, idx) => {
                                return (
                                    <div key={subway.subwayNo} className='d-flex text-muted pt-1'>
                                        <SubwayItem subway={subway} idx={idx} lat={lat} lon={lon} setStatnFNm={setStatnFNm} setStatnTNm={setStatnTNm} setSubwayNo={setSubwayNo} setOutStnNum={setOutStnNum} setSubwayIndex={setSubwayIndex} />
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }
            {!isLoading && subwayList.length == 0 && (
                <div id="subwayList">
                    <div className='text-muted text-center pt-1 '>
                        서비스 가능 지역이 아닙니다.
                    </div>

                </div>
            )}
        </>
    )
}

export default SubwayList;