import React from 'react';
import ReLoadingImg from '../../assets/Reload.gif';

const ReLoading = () => {
  return (
    // <div className="loadingBackground">
        <img src={ReLoadingImg} alt="로딩중" width="70%" margin="auto" display="block" />
    //     <div className="loadingText">잠시만 기다려 주세요.</div>
    // </div>
  )
}

export default ReLoading;