import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import * as client from '../../lib/api';
import ReLoading from '../common/ReLoading';
import RecommendSubItem from './RecommendSubItem';

const style = {
    margin: "0"
}

function RecommendContent(props) {
    const { recommendList, lat, lon, parentId /*, setParentOpen*/ } = props;
    const { getDetail } = props;
    let [storeId, setStoreId] = useState("");

    // 상점번호를 상점상세로 전달하기 위함
    const selectStore = (storeNo) => {
        console.log("LIST :" + storeNo);
        getSelectStore(storeNo);
    }

    const getSelectStore = (storeNo) => {
        console.log("LIST Container :"+storeNo);
        sessionStorage.setItem(
            `__select_recommend_No`,
            JSON.stringify({
                selectStoreNo:  storeNo,
            })
          );
        sessionStorage.setItem(
            `__next_recommend_scroll_Id`,
            JSON.stringify({
                storeNo:  storeNo,
            })
          );
        sessionStorage.setItem(
            `__next_recommend_scroll_${storeNo}`,
            JSON.stringify({
              x: window.pageXOffset,
              y: window.pageYOffset,
            })
        );
        // sessionStorage.setItem(
        //     `__next_recommend_scroll_temp`,
        //     JSON.stringify({
        //         storeNo: `${storeNo}`,
        //         tempScroll: window.pageYOffset,
        //     })
        // );
    }

    // 상점 상세정보 전달하
    const setDetail = (response) => {
        getDetail(response);
        const scrollId = sessionStorage.getItem(`__next_recommend_scroll_Id`);
        if (scrollId) {
            const { storeNo } = JSON.parse(scrollId);
            // 선택된 리스트 음영처리
            setStoreId(storeNo);
        }
    }

    return (
        <motion.div layout
            initial={{ opacity: 0, borderRadius: 10 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
                fontSize: "1.13em"
            }}>
            {recommendList &&
                recommendList.map((store, index) => {
                    return (
                        <div key={index} className='d-flex text-muted pt-1'>
                            <RecommendSubItem store={store} lat={lat} lon={lon} selectStore={selectStore} setDetail={setDetail} parentId={parentId} /* setParentOpen={setParentOpen}*//>
                        </div>
                    )
                })
            }
        </motion.div>
    );
}

export default RecommendContent;