import React, { useState } from 'react';
import * as client from '../lib/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let style = {
    margin: "5px"
}

function AdminContainer() {
    const [inputAdderss, setInputAdderss] = useState('');

    const coordinate = async () => {
        let response = null;
        try {
            response = await client.fetchCoordinate(inputAdderss);

            if (response.data.latitude && response.data.longitude) {
                // 이전 화면으로 돌아가기 위해 url정보를 임시 저장
                const location = sessionStorage.getItem(`__current_location_url`);

                console.log(response.data.latitude);
                console.log(response.data.longitude);

                sessionStorage.setItem(`__change_lat`, response.data.latitude);
                sessionStorage.setItem(`__change_lon`, response.data.longitude);

                toast(response.data.result);
                setTimeout(() => {
                    window.location.replace("/" + location);
                    sessionStorage.removeItem(`__current_location_url`);
                }, 2000);
            } else {
                toast(response.data.result);
            }
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    const handleAddressChange = (e) => {
        // 최대 글자 수 설정
        const maxLength = 40;
        const value = e.target.value;
        let trimmedValue;
        // 최대 글자 수 초과 시 다음 줄로 넘어가지 않음
        if (maxLength <= value.length) {
            trimmedValue = value.slice(0, maxLength);
            setInputAdderss(trimmedValue);
        } else {
            setInputAdderss(value);
        }
    }

    const resetAddress = () => {
        const message = "위치 정보를 초기화 하시겠습니까?";
        if (window.confirm(message)) {
            toast("위치 정보가 초기화 되었습니다.");

            sessionStorage.removeItem(`__change_lat`);
            sessionStorage.removeItem(`__change_lon`);

            const location = sessionStorage.getItem(`__current_location_url`);
            if (location) {
                setTimeout(() => {
                    window.location.replace("/" + location);
                }, 2000);
            } else {
                setTimeout(() => {
                    window.location.replace("/");
                }, 2000);
            }
        }
    }

    // 주소 조회창 엔터 막기
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Enter 키의 기본 동작 막기
        }
    };

    return (
        <div className='admin-container' style={{
            display: "grid",
            margin: "10px"
        }}>
            <span style={style}>주소 조회</span>
            <textarea className='input-data' onChange={handleAddressChange} style={style} onKeyDown={handleKeyDown} maxLength={40}></textarea>
            <div style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                justifyItems: "center"
            }}>
                <button style={{
                    width: "80%"
                }} onClick={coordinate}>변경</button>
                <button style={{
                    width: "80%"
                }} onClick={resetAddress}>초기화</button>
            </div>
            <ToastContainer position="bottom-center" autoClose={2000} />
        </div>
    )
}

export default AdminContainer;