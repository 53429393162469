import React from 'react';
// import Header from '../component/common/Header';
// import Footer from '../component/common/Footer';

function MainLayout({/* address, storeNo, setUrl,*/ children }) {

  return (
    <>
        {/* <Header address={address} storeNo={storeNo}/> */}
        {children}
        {/* <Footer setUrl={setUrl}/> */}
    </>
  )
}

export default MainLayout;