import React from 'react'
import DetailHeader from '../component/common/DetailHeader'

const DetailLayout = (props) => {
  const { children,  store, subway, recommendStore, setIndex, setSubwayIndex, setRecommendIndex} = props;

  return (
    <>
      <DetailHeader recommendStore={recommendStore} store={store} subway={subway} setIndex={setIndex} setSubwayIndex={setSubwayIndex} setRecommendIndex={setRecommendIndex} />
        {children}
    </>
  )
}

export default DetailLayout
