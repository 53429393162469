import React, { useRef, useState, useEffect } from 'react';
import MainLayout from '../layout/MainLayout';
import ChatContainer from '../container/ChatContainer';
import {
    ChakraProvider, theme//, useColorMode, useColorModeValue, IconButton
} from '@chakra-ui/react';
// import { FaMoon, FaSun } from 'react-icons/fa';

function ChatApp() {
    return (
        <ChakraProvider theme={theme}>
            {/* <ColorModeSwitcher justifySelf="flex-end" ml={2} /> */}
            <MainLayout>
                <ChatContainer />
            </MainLayout>
        </ChakraProvider>
    );
}
// const ColorModeSwitcher = props => {
//     const { toggleColorMode } = useColorMode();
//     const text = useColorModeValue('dark', 'light');
//     const SwitchIcon = useColorModeValue(FaMoon, FaSun);

//     return (
//         <IconButton
//             size="md"
//             fontSize="lg"
//             aria-label={`Switch to ${text} mode`}
//             variant="ghost"
//             color="current"
//             marginLeft="2"
//             onClick={toggleColorMode}
//             icon={<SwitchIcon />}
//             {...props}
//         />
//     );
// };
export default ChatApp;
