import React, { useEffect, useState } from 'react';
import StoreListContainer from '../container/StoreListContainer';
import MainLayout from '../layout/MainLayout';
import StoreDetailContainer from '../container/StoreDetailContainer';
import DetailLayout from '../layout/DetailLayout';
import SubwayContiner from '../container/SubwayContiner';
import SubwayMapContainer from '../container/SubwayMapContainer';
import RecommendContainer from '../container/RecommendContainer';
import RecommendDetailContainer from '../container/RecommendDetailContainer';
import ChatContainer from '../container/ChatContainer';
import {
    ChakraProvider//, useColorMode, useColorModeValue, IconButton
} from '@chakra-ui/react';

const StorePage = (props) => {
    const { url, address, setAddress, storeNo, setIndex, subwayIndex, setSubwayIndex, recommendIndex, setRecommendIndex } = props;

    // 공통
    const [lat, setLat] = useState("");             // 좌표
    const [lon, setLon] = useState("");             // 좌표

    // 스토어
    const [store, setStore] = useState(null);       // 상점
    const [detail, setResponse] = useState("");     // 상세화면

    // 지하철
    const [subway, setSubway] = useState("");
    const [statnFNm, setStatnFNm] = useState("");   // 이전 역 명
    const [statnTNm, setStatnTNm] = useState("");   // 다음 역 명
    const [subwayNo, setSubwayNo] = useState("");   // 지하철 번호
    const [outStnNum, setOutStnNum] = useState(""); // 지하철 역 명

    // 추천
    const [recommendStore, setRecommendStore] = useState(null);       // 상점
    const [recommendDetail, setRecommendResponse] = useState("");     // 상세화면

    // 상세화면 중복 방어
    useEffect(() => {
        if (url === "list") {
            setRecommendIndex(null);
            setSubwayIndex(null);
        } else if (url === "recommend") {
            setIndex(null);
            setSubwayIndex(null);
        } else if (url === "subway") {
            setIndex(null);
            setRecommendIndex(null);
        }
    });

    // 스크롤 이동
    useEffect(() => {
        const currentUrl = sessionStorage.getItem(`__current_url`);
        const _scroll = sessionStorage.getItem(`__current_scroll_${currentUrl}`);
        if (_scroll) {
            //스크롤 복원 후 저장된 위치 제거

            const { x, y } = JSON.parse(_scroll);
            window.scrollTo(x, y);
        } else {
            window.scrollTo(0, 0);
        }
    }, [sessionStorage.getItem(`__current_scroll_${url}`)]);

    return (
        <div>
            <div className={storeNo ? 'modal-open' : 'modal-close'}>
                {storeNo &&
                    <DetailLayout store={store} setIndex={setIndex}>
                        <StoreDetailContainer setStore={setStore} detail={detail} lat={lat} lon={lon} storeNo={storeNo} setIndex={setIndex} store={store} address={address} />
                    </DetailLayout>
                }
            </div>
            <div className={storeNo ? 'modal-close' : (url === "list" ? '' : 'minus-index')}>
                <MainLayout>
                    <StoreListContainer setAddress={setAddress} setResponse={setResponse} setLat={setLat} setLon={setLon} setIndex={setIndex} />
                </MainLayout>
            </div>
            <div className={subwayIndex ? 'modal-open' : 'modal-close'}>
                {subwayIndex && <DetailLayout subway={subway} setSubwayIndex={setSubwayIndex}>
                    <SubwayMapContainer setSubway={setSubway} detail={detail} lat={lat} lon={lon} statnFNm={statnFNm} statnTNm={statnTNm} subwayNo={subwayNo} outStnNum={outStnNum} setSubwayIndex={setSubwayIndex} />
                </DetailLayout>
                }
            </div>
            <div className={subwayIndex ? 'modal-close' : (url === "subway" ? '' : 'minus-index')}>
                <MainLayout>
                    <SubwayContiner setAddress={setAddress} setLat={setLat} setLon={setLon} setResponse={setResponse} setStatnFNm={setStatnFNm} setStatnTNm={setStatnTNm} setSubwayNo={setSubwayNo} setOutStnNum={setOutStnNum} setSubwayIndex={setSubwayIndex} />
                </MainLayout>
            </div>
            <div className={recommendIndex ? 'modal-open' : 'modal-close'}>
                {recommendIndex &&
                    < DetailLayout recommendStore={recommendStore} setRecommendIndex={setRecommendIndex}>
                        <RecommendDetailContainer setRecommendStore={setRecommendStore} recommendDetail={recommendDetail} lat={lat} lon={lon} recommendIndex={recommendIndex} setRecommendIndex={setRecommendIndex} address={address} />
                    </DetailLayout>
                }
            </div>
            <div className={recommendIndex ? 'modal-close' : (url === "recommend" ? '' : 'minus-index')}>
                <MainLayout address={address}>
                    <RecommendContainer setAddress={setAddress} setRecommendResponse={setRecommendResponse} setLat={setLat} setLon={setLon} setRecommendIndex={setRecommendIndex} />
                </MainLayout>
            </div>
            <ChakraProvider>
                {/* <ColorModeSwitcher justifySelf="flex-end" ml={2} /> */}
                <MainLayout address={address}>
                    <ChatContainer url={url} />
                </MainLayout>
            </ChakraProvider>
            <div className='custom_overlay' />
        </div>
    );
}

export default StorePage;