import React, { useState, useEffect } from 'react';
import PointList from '../component/point/PointList';
import * as client from '../lib/api';

function PointContiner() {
  const [pointList, setPointList] = useState([]);
  const [userNo, setUserNo] = useState("devoh@tsware.co.kr");
  const [isLoading, setIsLoading] = useState(false);
  // const [pointList, setPointList] = useState([
  //   {
  //     id: 1,
  //     "date": "12.1",
  //     "storeNm" : "크로플각1",
  //     "point" : "150"
  //   },
  //   {
  //     id: 2,
  //     "date": "12.2",
  //     "storeNm" : "크로플각2",
  //     "point" : "150"
  //   },
  //   {
  //     id: 3,
  //     "date": "12.3",
  //     "storeNm" : "크로플각3",
  //     "point" : "150"
  //   }
  // ]);

  const listPoint = async(userNo) => {
    setIsLoading(true);
    try {
      const response = await client.fetchPointList(userNo);
      setPointList(response.data);
      setIsLoading(false);
    } catch(e) {
      setIsLoading(false);
      throw e;
    }
  }

  useEffect(() => {
    console.log("Point");
    listPoint(userNo);
  }, []);

  return (
    <div>
        <PointList pointList={pointList} />
    </div>
  )
}

export default PointContiner;