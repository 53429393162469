import React, { useEffect } from 'react';
import * as client from '../lib/api';
import logo from './../assets/adcashcow.png';

const Location = (props) => {

    const { store, subway, locType, mapType, setTotalTime } = props;

    const { naver } = window;

    const findLocation = async () => {
        let myLat = null;
        let myLon = null;
        let map = null;
        let location = null;
        let myLocation = null;
        let endpoint = null;
        let zoomLvl = 17;



        navigator.geolocation.getCurrentPosition((position) => {
            myLat = position.coords.latitude;
            myLon = position.coords.longitude;

            if (sessionStorage.getItem(`__change_lat`) || sessionStorage.getItem(`__change_lon`)) {
                myLat = sessionStorage.getItem(`__change_lat`);
                myLon = sessionStorage.getItem(`__change_lon`);
            }

            console.log("myLat:" + myLat)
            console.log("myLon:" + myLon)



            if (!naver) return;

            const container = document.getElementById("map");
            if (mapType && mapType === "subway") {
                var lat = subway.convY;
                var lon = subway.convX;

                console.log("lat:" + lat)
                console.log("lon:" + lon)
                // 지도에 표시할 위치의 위도와 경도 좌표를 파라미터로 넣어줍니다.
                location = new naver.maps.LatLng(lat, lon);

                endpoint = subway.stnKrNm
                if (subway.distance > 400 && subway.distance < 1000) {
                    zoomLvl = 16;
                } else if (subway.distance > 1000 && subway.distance < 1500) {
                    zoomLvl = 15;
                } else if (subway.distance > 1500) {
                    zoomLvl = 14;
                }


            } else {
                var lat = store.adLocLat;
                var lon = store.adLocLon;

                console.log("lat:" + lat)
                console.log("lon:" + lon)
                // 지도에 표시할 위치의 위도와 경도 좌표를 파라미터로 넣어줍니다.
                location = new naver.maps.LatLng(lat, lon);

                endpoint = store.storeNm
                if (store.distance > 400 && store.distance < 600) {
                    zoomLvl = 16;
                } else if (store.distance > 600 && store.distance < 800) {
                    zoomLvl = 15;
                } else if (store.distance > 800) {
                    zoomLvl = 14;
                }
            }

            // 현재위치
            myLocation = new naver.maps.LatLng(myLat, myLon);


            const mapOptions = {
                center: myLocation,
                zoom: zoomLvl,
                minZoom: 6,
                zoomControl: false,
                zoomControlOptions: {
                    position: naver.maps.Position.TOP_RIGHT,
                },
            };

            map = new naver.maps.Map(container, mapOptions);

            var myMarker = new naver.maps.Marker({
                position: myLocation,
                map: map,
                icon: {
                    content: '<img alt="marker" src="' + logo + '" ' + //마커에 사용할 이미지
                        'style="margin: 0px; padding: 0px; border: 0px solid transparent; display: block; max-width: none; max-height: none; ' +
                        '-webkit-user-select: none; position: absolute; width: 30px; height: 30px; left: 0px; top: 0px;">',
                    size: new naver.maps.Size(30, 30),
                    anchor: new naver.maps.Point(15, 30)
                },
                animation: naver.maps.Animation.BOUNCE
            });

            var myContentString = [
                '<div class="iw_inner">',
                '내위치',
                '</div>'
            ].join('');

            var myInfowindow = new naver.maps.InfoWindow({
                content: myContentString
            });

            naver.maps.Event.addListener(myMarker, "click", function (e) {
                if (myInfowindow.getMap()) {
                    myInfowindow.close();
                } else {
                    myInfowindow.open(map, myMarker);
                }
            });
            myInfowindow.open(map, myMarker);


            var marker = new naver.maps.Marker({
                position: location,
                map,
            });

            var contentString = [
                '<div class="iw_inner">',
                endpoint,
                '</div>'
            ].join('');

            var infowindow = new naver.maps.InfoWindow({
                content: contentString
            });

            naver.maps.Event.addListener(marker, "click", function (e) {
                if (infowindow.getMap()) {
                    infowindow.close();
                } else {
                    infowindow.open(map, marker);
                }
            });
            infowindow.open(map, marker);

            getPolyLine();

        })



        async function getPolyLine() {
            console.log({ myLocation });
            console.log({ location });


            try {
                let response;
                let strokeColor = "#0000FF";

                if (locType === "drive") {
                    response = await client.fetchPolyLine(myLocation, location);
                    strokeColor = "#FF0000";
                } else {
                    response = await client.fetchPedestrain(myLocation, location)
                }

                console.log(response);
                const paths = response.data.polyLineList;

                const totalTime = response.data.totalTime;
                if (totalTime) {
                    console.log("totalTime:::::::::" + parseInt(totalTime / 60));
                    console.log("totalTime:::::::::" + totalTime % 60);
                    if (parseInt(totalTime / 60) === 0) {
                        setTotalTime(totalTime % 60 + "초", totalTime);
                    } else {
                        setTotalTime(parseInt(totalTime / 60) + "분", totalTime);
                    }

                }

                if (paths != null) {
                    let polylinePath = [];
                    paths.map((path) => {
                        polylinePath.push(new naver.maps.LatLng(path[1], path[0]));
                    });
                    console.log("polyLine" + polylinePath)
                    const polyline = new naver.maps.Polyline({
                        path: polylinePath, //좌표배열
                        strokeColor: strokeColor, //선의 색 파랑,빨강 
                        strokeOpacity: 0.8, //선의 투명도
                        strokeWeight: 4, //선의 두께
                        endIcon: naver.maps.PointingIcon.BLOCK_ARROW,
                        map: map, //만들어 놓은 지도
                    });
                }
            } catch (e) {
                console.log(e);
                throw e;
            }
        }

    }

    useEffect(() => {
        findLocation()
    }, [store, locType]);

    return (

        <div id="map" style={
            (mapType === "subway"
                ? { width: "100%", height: "510px", backgroundColor: "#f2f2f2" }
                : { width: "100%", height: "410px", backgroundColor: "#f2f2f2" }
            )}></div>
    )
}

export default Location;
