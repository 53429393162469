import React, { useRef, useState, useEffect } from 'react';
import MainLayout from '../layout/MainLayout';
import CameraContainer from '../container/CameraContainer';

function CameraApp() {    
    return (
        <div>
            <div>
                <MainLayout>
                    <CameraContainer/>
                </MainLayout>
            </div>
        </div>
    );
}

export default CameraApp;
