import React from 'react';
import MainLayout from '../layout/MainLayout';
import PointContiner from '../container/PointContiner';

function PointPage() {
  return (
    <div>
        <MainLayout>
            <PointContiner />
        </MainLayout>      
    </div>
  )
}

export default PointPage;