import React /*, { useEffect, useState }*/ from 'react';
import Loading from '../common/Loading';
import RecommendItem from './RecommendItem';
function RecommendList(props) {
    const { recommendList, isLoading, lat, lon } = props;
    const { getDetail } = props;

    return (
        <>
            {isLoading && (
                <Loading />
            )}
            {!isLoading && recommendList && (
                <div id="recommendList">
                    <div>
                        {recommendList.sentiList && recommendList.sentiList.length > 0 &&
                            <RecommendItem lat={lat} lon={lon} lineStyle='line2_color' recommendList={recommendList.sentiList} getDetail={getDetail} parentId={"딥러닝 리뷰 감정분석"}/>
                        }
                    </div>
                    <div id="newStoreList">
                        {recommendList.newStoreList && recommendList.newStoreList.length > 0 &&
                            <RecommendItem lat={lat} lon={lon} lineStyle='line1_color' recommendList={recommendList.newStoreList} getDetail={getDetail} parentId={"신규 상점"}/>
                        }
                    </div>
                    <div id="reFoodTasteList">
                        {recommendList.reFoodTasteList && recommendList.reFoodTasteList.length > 0 &&
                            <RecommendItem lat={lat} lon={lon} lineStyle='line3_color' recommendList={recommendList.reFoodTasteList} getDetail={getDetail} />
                        }
                    </div>
                    <div id="reCostEffectiveList">
                        {recommendList.reCostEffectiveList && recommendList.reCostEffectiveList.length > 0 &&
                            <RecommendItem lat={lat} lon={lon} lineStyle='line4_color' recommendList={recommendList.reCostEffectiveList} getDetail={getDetail} />
                        }
                    </div>
                    <div id="reKindList">
                        {recommendList.reKindList && recommendList.reKindList.length > 0 &&
                            <RecommendItem lat={lat} lon={lon} lineStyle='line5_color' recommendList={recommendList.reKindList} getDetail={getDetail} />
                        }
                    </div>
                    <div id="oldStoreList">
                        {recommendList.oldStoreList && recommendList.oldStoreList.length > 0 &&
                            <RecommendItem lat={lat} lon={lon} lineStyle='line6_color' recommendList={recommendList.oldStoreList} getDetail={getDetail} parentId={"오래된 상점"}/>
                        }
                    </div>
                    {/* 빈 배열이라 존재는 하지만 0임 */}
                    {recommendList.reFoodTasteList && recommendList.reCostEffectiveList && recommendList.reKindList 
                    && recommendList.sentiList && recommendList.newStoreList && recommendList.oldStoreList
                        && (recommendList.reFoodTasteList.length === 0 && recommendList.reCostEffectiveList.length === 0 && recommendList.reKindList.length === 0
                            && recommendList.sentiList.length === 0 && recommendList.newStoreList.length === 0 && recommendList.oldStoreList.length === 0) &&
                        <div className='text-muted text-center pt-1 '>
                            서비스 가능 지역이 아닙니다.
                        </div>
                    }
                </div>
            )}
        </>
    )
}

export default RecommendList;