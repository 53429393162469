import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SubwayDetail from '../component/subway/SubwayDetail';
import * as client from '../lib/api';
// import queryString from 'query-string';

const SubwayMapContainer = (props) => {

  // const { search } = useLocation();
  const { subwayNo, outStnNum, statnFNm, statnTNm, lat, lon, setSubwayIndex } = props;

  const [subway, setSubway] = useState(null);
  const [timeTable, setTimeTable] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const readSubway = async (subwayNo, outStnNum) => {
    
    setIsLoading(true);
    try {
      //역정보 조회
      const response = await client.fetchSubway(subwayNo, lat, lon);
      setSubway(response.data);
      props.setSubway(response.data);

      //열차시간표 조회
      const response2 = await client.fetchSubwayTime(outStnNum);
      setTimeTable(response2.data);
      //props.setTimeTable(response2.data);

      // 부모 스크롤 제거
      const styleElement = document.createElement('style');
      styleElement.textContent = 'html { overflow: hidden; }';
      document.head.appendChild(styleElement);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  };

  useEffect(() => {
    readSubway(subwayNo, outStnNum);
  }, [subwayNo]);


  return (<SubwayDetail subwayNo={subwayNo} subway={subway} timeTable={timeTable} statnFNm={statnFNm} statnTNm={statnTNm} isLoading={isLoading} setSubwayIndex={setSubwayIndex} />);

}

export default SubwayMapContainer;