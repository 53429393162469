import React, { useState, useEffect } from 'react';

const StoreFilter = ({uptaeList, getUptaeData}) => {


    const [btnActive, setBtnActive] = useState("");

    function toggleActive(nm, cnt){
        console.log(cnt);
        if(nm == '전체'){
            getUptaeData("", cnt)

        }else{
            getUptaeData(nm, cnt)
        }
        //setBtnActive((prev) => {
        //return e.target.value;
        //});
        setBtnActive(nm)
    };



    return (
    <>
        {uptaeList.map((uptae, idx) => {
        return (
            <span key={idx}>
                <button 
                value={uptae.uptaeNm}
                className={"btn active" + (uptae == btnActive ? " active" : "")}
                onClick={()=>{toggleActive(uptae.uptaeNm, uptae.count);}}
                >
                {uptae.uptaeNm}({uptae.count})
                </button>
            </span>
        );
      })}
      </>
    );
};

export default StoreFilter;