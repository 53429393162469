import React, { useState, useEffect } from 'react';
import RecommendList from '../component/recommend/RecommendList';
import * as client from '../lib/api';
import TopButton from '../component/common/TopButton';

const RecommendContainer = (props) => {
  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [recommendList, setRecommendList] = useState([]);
  const [address, setAddress] = useState("");

  const listRecommend = async (lat, lon) => {
    setIsLoading(true);
    try {
      const response = await client.fetchRecommendList(lat, lon);
      console.log(response);

      setRecommendList(response.data.recommendDTO);
      console.log(response.data.recommendDTO);
      setAddress(response.data.fullAddress);
      props.setAddress(response.data.fullAddress.substr(6));

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  }
  // 상점 상세정보 조회한 내용
  const getDetail = (response) => {
    props.setRecommendResponse(response);
  }
  // 상점 상세정보가 변경되는 경우
  useEffect(() => {
    const no = sessionStorage.getItem(`__next_recommend_scroll_Id`);
    if (no) {
      const { storeNo } = JSON.parse(no);
      props.setRecommendIndex(storeNo);
    }
  }, [getDetail]);

  useEffect(() => {
    console.log('현재 위치 구하기');
    // 현재 위치 좌표 구해서, 지하철 목록 조회
    navigator.geolocation.getCurrentPosition((position) => {
      // GPS 좌표
      var lat = position.coords.latitude;
      var lon = position.coords.longitude;

      if (sessionStorage.getItem(`__change_lat`) || sessionStorage.getItem(`__change_lon`)) {
        lat = sessionStorage.getItem(`__change_lat`);
        lon = sessionStorage.getItem(`__change_lon`);

        console.log("변경 위치 : " + lat);
        console.log("변경 위치 : " + lon);
      }

      console.log('lat', lat);
      console.log('lon', lon);

      // 현재 좌표로 지하철 리스트 조회
      listRecommend(lat, lon);
      setLat(lat);
      setLon(lon);

      props.setLat(lat);
      props.setLon(lon);
    })
  }, []);

  // 로딩 상태 감지
  useEffect(() => {
    if (isLoading) {
      // 로딩중인 경우 잠시만 기다려 주세요 화면으로 가림 처리 후 뒤 페이지 스크롤 막기
      const styleElement = document.createElement('style');
      styleElement.textContent = 'html { overflow: hidden; }';
      document.head.appendChild(styleElement);
    } else {
      // 로딩이 완료된 이후 막은 스크롤 제거
      // 적용된 스타일 (스크롤 방지)
      const styleElements = document.querySelectorAll('style');

      // NodeList를 배열로 변환
      const styleArray = Array.from(styleElements);

      // 배열의 마지막 요소 선택
      const lastStyleElement = styleArray.pop();

      // 마지막 요소가 존재하면 삭제
      if (lastStyleElement) {
        lastStyleElement.parentNode.removeChild(lastStyleElement);
      }
    }
  }, [isLoading]);
  
  return (
    <main className="container">
      <div className="my-2 p-2 bg-body rounded shadow-sm">
        <RecommendList recommendList={recommendList} isLoading={isLoading} lat={lat} lon={lon} getDetail={getDetail} />
        <TopButton />
      </div>
    </main>
  )
}

export default RecommendContainer;