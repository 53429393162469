import { motion } from "framer-motion";
import * as client from '../../lib/api';
import { useEffect, useState } from 'react';

let style = {
    color: "#c7254e"
}
let dateStyle = {
    color: "#008000"
}

function RecommendSubContent(props) {
    const { store, lat, lon, isOpen, isIndexOpen } = props;  // 전달된 값
    const { selectStore, setDetail } = props;  // 전달 해야할 값

    const [crawlTel, setCrawlTel] = useState("");
    const [crawlMenu, setCrawlMenu] = useState([]);
    const [crawlOriginMenu, setCrawlOriginMenu] = useState([]);
    const [crawlOpen, setCrawlOpen] = useState([]);
    const [isClicked, setIsClicked] = useState(false);

    // 리스트 클릭 이벤트
    const onClickStore = async () => {
        await selectStore(store.storeNo);   // 현재 선택된 스토어 번호
        console.log("Store Item:" + store.storeNo);

        // 상점 상세 호출
        const response = await client.fetchStore(store.storeNo, store.address, "N", lat, lon);

        setDetail(response);
    };

    const getFetchStore = async () => {
        if (isClicked) {
            try {
                sessionStorage.setItem(`__update_store_date`, store.modDttm);
                sessionStorage.setItem(`str_tel`, crawlTel);
                sessionStorage.setItem(`str_menu`, crawlOriginMenu);
                sessionStorage.setItem(`str_name`, store.storeNm);
                sessionStorage.setItem(`str_no`, store.storeNo);
                sessionStorage.setItem(`str_addr`, store.address);
                sessionStorage.setItem(`div_cd`, "str");

                // 지하철 내용 삭제
                sessionStorage.removeItem(`sub_name`);
                sessionStorage.removeItem(`sub_line`);
                sessionStorage.removeItem(`sub_dx`);

                fetchSave();
            } catch (e) {
                throw e;
            }
        }
    }

    const fetchSave = () => {
        const date = new Date();
        // 년, 월, 일, 시간, 분, 초를 각각 추출합니다.
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        // yyyy-mm-dd HH:MM:SS 형식으로 날짜를 조합합니다.
        const formattedDate = `${year}${month}${day}${hours}${minutes}${seconds}`;

        // 접속 시간
        sessionStorage.setItem(`date`, formattedDate);

        let updateStoreDate = sessionStorage.getItem(`__update_store_date`);

        // 업데이트 날짜가 없는 경우 발생
        if (updateStoreDate !== null && updateStoreDate !== "") {
            updateStoreDate = updateStoreDate.replace(/-/g, '');
            sessionStorage.setItem(`str_date`, updateStoreDate);
        }

        const url = document.location.href;
        const splitUrl = url.split('/');
        const location = splitUrl[splitUrl.length - 1];

        const keys = Object.keys(sessionStorage);
        let json = {}

        // 각 키를 이용하여 값(value)에 접근하고 출력하기
        keys.forEach(key => {
            let value = sessionStorage.getItem(key);
            // 주소의 경우 ,를 지우고 구분을 위해 '|' 추가. csv파일 생성을 위함
            if (key === "str_addr") {
                value = value.replace(/,/g, '');
            }

            if (!key.includes('__')) {
                json[key] = value;
            }
        });

        client.fetchSave(json);
    }

    const getFetchCrawl = async () => {
        if (isOpen) {
            let menus = store.crawlMenuList;
            const tel = store.crawlTel;
            let open = store.crawlOpenList;
            setCrawlOriginMenu(store.crawlMenu);

            if (tel !== null) {
                setCrawlTel(tel);
            }

            if (open !== null && open.length > 0) {
                const openList = [];

                for (let i = 0; i < open.length; i += 2) {
                    const _opening = open[i];
                    let _openTime = open[i + 1];

                    const openItemElement = {
                        opening: _opening,
                        openTime: _openTime
                    };

                    openList.push(openItemElement);
                }
                setCrawlOpen(openList);
            }
            
            if (menus !== null && menus.length > 0) {
                // 메뉴와 가격이 번갈아가며 출력되도록 조합
                const menuList = [];
                let menuItemElement = null;

                for (let i = 0; i < menus.length; i += 2) {

                    const _menu = menus[i];
                    let _price = menus[i + 1];

                    menuItemElement = {
                        menu: _menu,
                        price: _price,
                        index: i
                    };

                    menuList.push(menuItemElement);
                }

                setCrawlMenu(menuList);
            }
        }
        setIsClicked(!isClicked);
    }

    useEffect(() => {
        getFetchCrawl();
    }, [isOpen, isIndexOpen]);

    useEffect(() => {
        getFetchStore();
    }, [isClicked]);

    return (
        <motion.div className={"pb-1 mb-0 small lh-sm border-bottom w-100 " + (isOpen ? 'selectStore' : 'text-primary')} layout
            initial={{ opacity: 0, borderRadius: 10 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}>
            <div className={"d-flex justify-content-between "} >
                {store.adYn && <span className="text-dark-gray"><font>광고</font> {store.storeNm}</span>}
                {!store.adYn &&
                    <div style={{
                        display: "grid",
                        width: "100%",
                        color: "black",
                        fontSize: "14px"
                    }}>
                        <span style={{
                            margin: "5px 0px"
                        }}>{crawlTel && "전화번호 : "}<a href={"tel:"+crawlTel.replace(/-/g, '')}>{crawlTel}</a></span>
                        {crawlOpen.map((openItem, idx) => {
                            return (
                                <div key={idx} style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 4fr",
                                    margin: "3px 0px",
                                    lineHeight: "13px"
                                }}>
                                    <span>{openItem.opening}</span>
                                    <span style={{
                                        whiteSpace: "pre-wrap"
                                    }}>{openItem.openTime}</span>
                                </div>
                            )
                        })}
                        <div>
                            {crawlMenu.map((menuItem, idx) => {
                                return (
                                    <div key={idx} style={{
                                        display: "grid",
                                        gridTemplateColumns: "2.5fr 1fr",
                                        width: "100%",
                                        lineHeight: "18px"
                                    }}>
                                        <div key={menuItem.index}>{menuItem.menu}</div>
                                        <div>{menuItem.price}</div>
                                    </div>
                                )
                            })}
                        </div>
                        <div>
                            <button type="button" onClick={onClickStore}
                                className="btn btn-primary mx-1 mt-1 list-button" style={{ width: "98%" }} >지도 보기</button>
                        </div>
                    </div>
                }
            </div>
        </motion.div>
    );
}

export default RecommendSubContent;